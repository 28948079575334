import React, { Component } from "react"
import { Link } from "react-router-dom";
import { connect } from "react-redux"
import { Currency } from "../../../utils/formatMoney"

class CartLink extends Component<any, any> {

  constructor(props: any) {
    super(props)
  }

  getNumberOfItem() {
    let numberOfItem = 0

    if (this.props.items.length) {
      this.props.items.forEach(item => {
        numberOfItem += parseInt(item.qty)
      })
      return numberOfItem
    }
    return 0
  }

  getCartTotal() {
    let cartTotal = 0
    
    if (this.props.items.length) {
      this.props.items.forEach(item => {
        cartTotal += item.qty * item.price
      }) 
      return cartTotal
     }
     return 0
  }

  render() {
    return (
      <div style={{ fontWeight: 700, color: "#333" }}>
        {
          this.props.items.length ? (
            <span>
              CART: {this.getNumberOfItem()} / <Currency value={this.getCartTotal()}/>
            </span>
          ) : (
            <span>
              CART: 0 / IDR 0,00
            </span>
          )
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.cart.itemList
  }
}

export default connect(mapStateToProps, null)(CartLink)