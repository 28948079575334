import React, { Component } from "react"
import { Link } from "react-router-dom"
import "./Pagination.css"

class Pagination extends Component<any, any> {
  render() {

    const { page, perPage, totalItems } = this.props
    const totalPage = Math.ceil(totalItems / perPage)

    return (
      <>
        <nav aria-label="Pagination" style={{ display: "flex", justifyContent: "center", marginTop: 25, marginBottom: 25 }}>
          <ul className="pagination">
            <li className={`page-item ${page - 1 == 0 ? "disabled" : ""}`}>
              <Link to={`?page=${page - 1 == 0 ? page : page - 1}`}>
                <span className="page-link">◀</span>
              </Link>
            </li>
            {page - 1 == 0 ? (null) : (
              <li className={`page-item ${page - 1 == 0 ? "disabled" : ""}`}>
                <Link to={`?page=${page - 1 == 0 ? page : page - 1}`}>
                  <span className="page-link">{page - 1 == 0 ? "..." : page - 1}</span>
                </Link>
              </li>
            )}
            <li className="page-item active">
              <Link to={`?page=${page}`}>
                <span className="page-link">{page}</span>
              </Link>
            </li>
            {page * 1 + 1 <= totalPage ? (
              <li className="page-item">
                <Link to={`?page=${page * 1 + 1}`}>
                  <span className="page-link">{page * 1 + 1}</span>
                </Link>
              </li>
            ) : null }
            {page * 1 + 2 < totalPage ? (
              <li className="page-item">
                <Link to={`?page=${page * 1 + 2}`}>
                  <span className="page-link">{page * 1 + 2}</span>
                </Link>
              </li>
            ) : null }
            {totalPage > 1 ? (page * 1 + 1 >= totalPage ? (null) : (
              <li className="page-item">
                <Link to={`?page=${totalPage}`}>
                  <span className="page-link">{totalPage}</span>
                </Link>
              </li>
            )) : null }
            <li className={`page-item ${page * 1 + 1 > totalPage ? "disabled" : ""}`}>
              <Link to={`?page=${page * 1 + 1 > totalPage ? page : page * 1 + 1 }`}>
                <span className="page-link">▶</span>
              </Link>
            </li>
          </ul>
        </nav>
      </>
    )
  }
}

export default Pagination