import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

class AccountContainer extends Component<any, any> {

  constructor(props: any) {
    super(props)
  }

  getAccountRoute() {
    switch (this.props.location.pathname) {
      case "/account/change_password":
        return "CHANGE PASSWORD"
      case "/account/orders":
        return "ORDERS"
      case "/account/referral":
        return "REFERRAL"
      default:
        return "MY ACCOUNT"
    }
  }

  render() {
    return(
      <>
        <p style={{ fontFamily: "Roboto Slab", fontWeight: 400, fontSize: 10, color: "#777", textTransform: "uppercase", marginTop: 20, marginBottom: 20 }}>
          <Link to="/">HOME</Link> / {this.getAccountRoute() == "MY ACCOUNT" ? "MY ACCOUNT" : `MY ACCOUNT / ${this.getAccountRoute()}`}
        </p>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ display: "flex", flexDirection: "column", marginTop: 56, marginRight: 60, color: "#000", fontFamily: "Roboto Slab", fontWeight: 400, fontSize: 12 }}>
            <p style={{ fontFamily: "Montserrat", fontSize: 13, fontWeight: 400, color: "#333" }}>MY ACCOUNT</p>
            <Link style={{ color: "#777" }} to="/account">Account Information</Link>
            {/* <Link style={{ color: "#777" }} to="/account/change_password">Change Password</Link>
            <Link style={{ color: "#777" }} to="/account/orders">Order History</Link>
            <Link style={{ color: "#777" }} to="/account/referral">Referral</Link> */}
          </div>
          {this.props.children}
        </div>
      </>
    );
  }
}

export default withRouter<any>(AccountContainer)