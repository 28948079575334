import React, { Component } from "react"
import { graphQLClient } from "../../services/graphQL"
import StaticContent from "../../utils/staticContent/staticContent"
import "./Footer.css"

class Footer extends Component<any, any> {

  state = {
    socialLinks: [] as any
  }

  componentDidMount() {

    const query = `
    {
      siteConfig{
        siteId host fqdn template themeSettings {
          faviconURL facebookURL twitterURL youtubeURL backgroundURL instagramURL
        } 
      }
    }
    `
    
    graphQLClient.request(query)
      .then(data => this.setState({ socialLinks: data["siteConfig"].themeSettings }))
      .catch(err => console.warn("Terjadi kesalahan memuat Social Links ➜ " + err))

  }

  render() {

    const { socialLinks } = this.state

    return (
      <>
        <div className="footer-navigation">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <StaticContent 
                  title="Footer Links"
                  className="footer-navigation--global"
                />
              </div>
              <div className="col-lg-2">
              <StaticContent 
                  title="Footer Links 2"
                  className="footer-navigation--global"
                />
              </div>
              <div className="col-lg-2">
               <StaticContent 
                  title="Footer Links 3"
                  className="footer-navigation--global"
                />
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-4 footer-navigation--global">
                <h3>BE THE FIRST TO KNOW, JOIN OUR MAILING LIST</h3>
                <div className="input-group">
                  <input type="text" placeholder="E-mail Address" style={{ height: 44, borderRadius: 5, border: "#cccccc solid 1px", borderTopRightRadius: "0", borderBottomRightRadius: "0", padding: "14px 20px", fontFamily: "Helvetica", fontSize: 13, outline: "none", width: "auto" }} />
                  <button type="submit" style={{ background: "#0f0f0f", border: "none", width: 78, height: 44, color: "#fff", fontFamily: "Lato", fontWeight: 700, fontSize: 12, textTransform: "uppercase", borderRadius: 5, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>Submit</button>
                </div>

                <h3 className="footer-navigation-title mt-4">SOCIAL MEDIA</h3>
                  { 
                    socialLinks ? (
                      <>
                        { socialLinks.facebookURL ? (
                          <a href={socialLinks.facebookURL}>
                            <img style={{ marginRight: 10 }} src={require('./images/facebook.png')} />
                          </a>
                        ) : null }
                        { socialLinks.twitterURL ? (
                          <a href={socialLinks.twitterURL}>
                            <img style={{ marginRight: 10 }} src={require('./images/twitter.png')} />
                          </a>
                        ) : null }
                        { socialLinks.pinterestURL ? (
                          <a href={socialLinks.pinterestURL}>
                            <img style={{ marginRight: 10 }} src={require('./images/pinterest.png')} />
                          </a>
                        ) : null }
                        { socialLinks.youtubeURL ? (
                          <a href={socialLinks.youtubeURL}>
                            <img style={{ marginRight: 10 }} src={require('./images/youtube.png')} />
                          </a>
                        ) : null }
                        {/* <img src={require('./images/flickr.png')} /> */}
                      </>
                    ) : (
                      null
                    ) 
                  } 
              </div>
            </div>
          </div>
          <div className="footer-bottom text-right">
            <div className="container">
              <div className="row">
                <div className="col-sm-6 text-left footer-bottom-copyright" style={{ fontFamily: "Roboto Slab" }}>
                  <StaticContent 
                    title="Footer Text"
                    className="footer-navigation--global"
                  />
                </div>
                <div className="col-sm-6 text-right footer-bottom-bg">
                  <div className="footer-logo-mobile">
                    <StaticContent 
                      title="Payment Logo"
                      className="footer-navigation--global"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Footer