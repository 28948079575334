import React, { Component } from "react"
import { withRouter } from "react-router";
import queryString from "query-string"
import { titleCase } from "../../utils/titleCase"
import { ACCESS_TOKEN, BASE_URL } from "../../services/api";
import ProductList from "./ProductList";
import Pagination from "../../components/Pagination/Pagination";

// export type DetailedImagesDataType = {
//   color: any
//   file_basename: string
//   prod: string
//   url: string
// }

// export type DetailedVariantsDataType = {
//   friendly_id: string
//   id: number
//   price: number
//   product_code: string
//   stock: number
// }

// export type GeneralOptionsDataType = {
//   options: string[]
//   title: string
//   value: string
// }

// export type ProductDataType = {
//   advanced_pricing_rules: any
//   attachment: any
//   brand: string
//   category_ids: number[]
//   color: string
//   created_at: string
//   cross_tags: any[]
//   currency: string
//   custom_attribute1: string
//   custom_attribute2: string
//   custom_attribute3: string
//   custom_attribute4: string
//   custom_attribute5: string
//   custom_attribute_1: string
//   custom_attribute_2: string
//   decoration: any
//   description: string
//   detailed_images: DetailedImagesDataType[]
//   detailed_variants: { [key: string]: DetailedVariantsDataType }
//   fid: string
//   friendly_id: string
//   general_options: { [key: string]: GeneralOptionsDataType }
//   id: number
//   images: string[]
//   is_active: boolean
//   is_backorder: boolean
//   is_featured: boolean
//   is_ignore_stock: boolean
//   is_in_stock: boolean
//   is_new: boolean
//   label: any
//   label_fid: any
//   link: string
//   meta_description: string
//   meta_keywords: string
//   meta_title: string
//   options: { [key: string]: string[] }
//   origin_specification: string
//   price: string
//   price_raw: number
//   prices_by_color: {[key: string]: string }
//   product_code: string
//   short_description: string
//   size: string
//   sold_stock: number
//   specification: string
//   stock: number
//   title: string
//   type: string
//   variants: { [key: string]: string }
//   weight: number
//   wish_count: number
// }

// export type ProductContainerStateType = {
//   page: number
//   perPage: number
//   totalItems: number
//   totalInPage: number
//   productList: ProductDataType[]
// }

// export type responseJSONType = {
//   paging: {
//     next: string
//     page: number
//     total: number
//     total_in_page: number
//   }
//   products: ProductDataType[]
//   status: number
// }

class ProductContainer extends Component<any, any> {

  state: any = {
    page: 1,
    perPage: 12,
    totalItems: 0,
    totalInPage: 0,
    productList: [],
    generalSorts: [],
    pageTitle: "Products",
    isLoading: true
  }

  updatePageTitle() {

    let pathName = this.props.location.pathname.split("/")

    switch (pathName[2]) {
      case "category":
      case "search":
        this.setState({ 
          pageTitle: titleCase(pathName[3].split("-").join(" ").split("+").join(" ")) 
        })
      default:
        return "Products"
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0)

    this.fetchProductData()
    
    let currentPage = queryString.parse(this.props.location.search).page
    this.setState({ page: currentPage || 1 })
    this.updatePageTitle()

    let generalSorts = await fetch(BASE_URL + "general_sorts", {
      method: "GET",
      headers: {
        "X-SIRCLO-ACCESS-TOKEN": ACCESS_TOKEN
      },
      cache: "no-cache"
    })
    if (generalSorts.ok) {
      generalSorts.json()
        .then(data => this.setState({ generalSorts: data.general_sorts }))
    }
  }

  componentDidUpdate(prevProps) {
    window.scrollTo(0, 0)

    let currentPage = queryString.parse(this.props.location.search).page
    let previousPage = queryString.parse(prevProps.location.search).page
    if (currentPage !== previousPage) {
      this.setState({ page: currentPage || 1 })
      this.updatePageTitle()
      this.fetchProductData()
    }

    let queryType = this.props.match.url
    let prevQueryType = prevProps.match.url
    if( prevQueryType !== queryType ){
      this.updatePageTitle()
      this.fetchProductData()
    }
  }

  async fetchProductData() {
    this.setState({ isLoading: true })
    
    const page = queryString.parse(this.props.location.search).page || 1
    const { perPage } = this.state
    const { match } = this.props
    let queryType = (this.props.match.url).split("/")[2]
    let headers = { headers: { 
      "X-SIRCLO-ACCESS-TOKEN": ACCESS_TOKEN 
    }}
    let result: Response;
    let resultJSON: any;

    try {
      if (queryType === "category") {
        result = await fetch(`${BASE_URL}products/category/${match.params.categoryId}?page=${page}&items_per_page=${perPage}`, headers)
        resultJSON = await result.json()
      } else {
        result = await fetch(`${BASE_URL}products?page=${page}&items_per_page=${perPage}&filter_title=${match.params.filterTitle || ""}`, headers)
        resultJSON = await result.json()
      }
      if (resultJSON.paging) {
        this.setState({ 
          productList: resultJSON.products,
          totalItems: resultJSON.paging.total,
          totalInPage: resultJSON.paging.total_in_page,
          isLoading: false
        })
      } else {
        this.setState({
          productList: resultJSON.products,
          totalItems: resultJSON.products.length || 0,
          totalInPage: resultJSON.products.length || 0,
          isLoading: false
        })
      }
    }
    catch (err) {
      console.warn("Terjadi kesalahan memuat Products ➜ " + err)
    }
  }

  render() {
    const { 
      productList, 
      page, 
      perPage,
      totalItems, 
      totalInPage ,
      pageTitle,
      generalSorts,
      isLoading
    } = this.state

    return (
      <>
        <ProductList 
          pageTitle={pageTitle}
          productList={productList} 
          loading={isLoading}
        />
        <Pagination 
          page={page}
          perPage={perPage}
          totalItems={totalItems}
          totalInPage={totalInPage}
        />
      </>
    )
  }
}

export default withRouter<any>(ProductContainer)