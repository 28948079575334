import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

class ThankYou extends Component {

  render() {
    return (
      <>
        <Helmet>
          <title>Thank You</title>
        </Helmet>
        <div style={{ fontFamily: "Lato", textAlign: "center", marginTop: 50 }}>
          <h3 style={{ fontSize: 16, color: "#222", width: "100%" }}>Order Success!</h3>
          <img className="img-fluid" src={require('./images/check.png')} style={{ marginTop: 50, marginBottom: 50 }} />
          <div style={{ maxWidth: 920, border: "1px solid #d8e6e9", background: "#fafafa", padding: 25, margin: "0 auto" }}>
            <span style={{ fontWeight: 700 }}>Terima kasih telah melakukan pembelian di toko kami.</span><br /><br />
            <p style={{ fontSize: 13 }}>Jika pembayaran telah dilakukan, Detail pembelian dilampirkan melalui email oleh toko kami.</p>

            <Link to="/products/all">
              <div className="btn" style={{ borderRadius: 3, background: "#222", marginTop: 15, marginBottom: 15, maxWidth: 206, color: "#fff", textTransform: "uppercase", fontWeight: 700, fontSize: 13 }}>
                Continue Shopping
              </div>
            </Link>
          </div>
        </div>
      </>
    )
  }

}

export default ThankYou