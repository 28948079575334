import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"
import { toast } from "react-toastify"
import moment from "moment"
import "moment/locale/id"
import { BASE_URL, LOGISTIX_BASE_URL, ACCESS_TOKEN } from "../../services/api";
import { titleCase } from "../../utils/titleCase"
import CustomInputLarger from "../../components/CustomInputLarger"

class AccountRegister extends Component<any, any> {

  state = {
    email: "",
    password: "",
    confirmPassword: "",
    name: "",
    mobilePhone: "",
    birthDate: new Date(),
    address: "",
    country: "",
    province: "",
    city: "",
    postalCode: "",
    rawProvinceData: [] as any,
    countryDropdown: [] as any,
    provinceDropdown: [] as any,
    cityDropdown: [] as any
  }

  async componentDidUpdate(prevProps, prevState) {
    const { province, rawProvinceData } = this.state;

    var cityDistricts = [] as any;
    if (prevState.province !== province) {
      rawProvinceData.forEach(state => {
        if (state.provider_data == province) {
          state.cities.forEach(city => {
              city.districts.forEach(district => {
                  cityDistricts.push(district.provider_data);
              });
          });
        }
      });
      this.setState({ cityDropdown: cityDistricts })
    }
  }

  async componentDidMount() {

    try {
      let result = await fetch(`${LOGISTIX_BASE_URL}v1/location/countries/srcl`);
      if (result.ok) {
        this.setState({ countryDropdown: (await result.json()).locations})
      } else {
        throw new Error(result.statusText)
      }
      let detailedResult = await fetch(`${LOGISTIX_BASE_URL}v1/location/srcl`);
      let detailedResultJSON = await detailedResult.json();
      
      let rawProvinceData = detailedResultJSON.locations[0].provinces;
      let provinceList = detailedResultJSON.locations[0].provinces.map(province => province.provider_data);
      if (detailedResult.ok) {
        this.setState({ 
          provinceDropdown: provinceList,
          rawProvinceData 
        })
      } else {
        throw new Error(result.statusText)
      }
    } catch(err) {
      console.warn('Terjadi kesalahan memuat daftar negara ➜ ' + err);
    }
  }

  async onRegister() {
    const { email, password, confirmPassword, country, city, name, mobilePhone, birthDate, address, province, postalCode } = this.state

    moment.locale('id')
    let formattedBirthDate = moment(birthDate).format('LL').toString()

    var body = new FormData()
    body.append("email", email)
    body.append("password", password)
    body.append("confirm password", confirmPassword)
    body.append("salutation", "Mr")
    body.append("first_name", name)
    body.append("phone", mobilePhone)
    body.append("address_line1", address)
    body.append("postal code", postalCode)
    body.append("country", country)
    body.append("state", province.toUpperCase())
    // body.append("city", "Kota " + titleCase(city.split('-').join(' - ')))
    body.append("city", city)
    body.append("dob", formattedBirthDate)

    try {
      fetch(`${BASE_URL}members`, {
        method: "POST",
        headers: {
          "X-SIRCLO-ACCESS-TOKEN": ACCESS_TOKEN,
        },
        body
      })
      .then(async (res) => {
        const response = await res.json()
        if (response.status == 400) {
          toast.error(response.message)
        } else {
          this.props.history.push("/account/login")
          toast.success(response.message)
        }
      })
    }
    catch(err) {
      toast.error("Terjadi kesalahan saat melakukan pendaftaran ➜ " + err)
    }
  }

  render() {
    const { email, password, confirmPassword, country, city, name, mobilePhone, birthDate, address, province, postalCode, countryDropdown, provinceDropdown, cityDropdown } = this.state;  

    return (
      <div>
        <Helmet>
          <title>Register</title>
        </Helmet>
        <p style={{ fontFamily: "Roboto Slab", fontWeight: 400, fontSize: 10, color: "#777", textTransform: "uppercase", marginTop: 20, marginBottom: 20 }}>
          <Link to="/">HOME</Link> / <Link to="#">REGISTER</Link>
        </p>
        <h1 style={{ fontSize: 32, textTransform: "uppercase", marginTop: 60, marginBottom: 65, textAlign: "center", fontFamily: "Lato", fontWeight: 900 }}>REGISTER</h1>
          <CustomInputLarger onChange={(e) => this.setState({ email: e.target.value })} value={email} type="text" label="Email"/>
          <CustomInputLarger onChange={(e) => this.setState({ password: e.target.value })} value={password} type="password" label="Password"/>
          <CustomInputLarger onChange={(e) => this.setState({ confirmPassword: e.target.value })} value={confirmPassword} type="password" label="Confirm Password"/>
          <CustomInputLarger onChange={(e) => this.setState({ name: e.target.value })} value={name} type="text" label="Name"/>
          <CustomInputLarger onChange={(e) => this.setState({ mobilePhone: e.target.value })} value={mobilePhone} type="text" label="Mobile Phone"/>
          <CustomInputLarger onChange={(e) => this.setState({ birthDate: e.target.value })} value={birthDate} type="date" label="Birthdate"/>
          <p style={{ fontFamily: "Lato", fontWeight: 400, fontSize: 20, marginBottom: 15 }}>Delivery Address</p>
          <CustomInputLarger onChange={(e) => this.setState({ address: e.target.value })} value={address} type="text" label="Address"/>
          <CustomInputLarger onChange={e => this.setState({ country: e.target.value })} label="Country" type="dropdown" value={country}>
            {
              countryDropdown.map((country, index) => {
                return (
                  <option key={index} value={country.location_code}>
                    {country.provider_data}
                  </option>
                )
              })
            }
          </CustomInputLarger>
          <CustomInputLarger onChange={(e) => this.setState({ province: e.target.value })} value={province} suggestions={country === "ID" ? provinceDropdown : []} type="text-autosuggest" label="State/Province"/>
          <CustomInputLarger onChange={(e) => this.setState({ city: e.target.value })} value={city} suggestions={cityDropdown} type="text-autosuggest" label="City/District"/>
          <CustomInputLarger onChange={(e) => this.setState({ postalCode: e.target.value })} value={postalCode} type="number" label="Postal Code"/>
          <small id="emailHelp" className="form-text text-muted" style={{ marginBottom: 20, fontFamily: "Roboto Slab", fontSize: 10 }}>Fields marked with <span style={{ color: "#ffa500", marginLeft: 3 }}>*</span> are required.</small>
          <button onClick={() => this.onRegister()} className="btn btn-dark btn-lg" style={{ background: "#222", fontFamily: "Montserrat", textTransform: "uppercase", fontSize: 13, padding: 14, width: 170, border: "none", marginBottom: 35 }} >Register</button>
      </div>
    );
  }
}

export default withRouter<any>(AccountRegister);