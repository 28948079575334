let siteConfig: any;
if ((window as any).__PRELOADED_STATE.siteConfig) {
  siteConfig = (window as any).__PRELOADED_STATE.siteConfig
}

if (!siteConfig) {
  global.console.error("Config not defined.")
}

export const SIRCLO_SITE_ID =  siteConfig.id
export const SIRCLO_SITE_HOST = siteConfig.host
export const SIRCLO_WITH_HTTPS = siteConfig.withHttps 
export const SIRCLO_WITH_QUEUE_ORDER = siteConfig.withQueuePlaceOrder || false

export const SIRCLO_FAVICON_URL = siteConfig.themeSettings.faviconURL
export const SIRCLO_STORE_NAME =  siteConfig.themeSettings.storeName
export const SIRCLO_LOGO_URL = siteConfig.themeSettings.logoURL 