import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import StaticContent from "../utils/staticContent/staticContent"

class Contact extends Component {

  state = {
    name: "",
    email: "",
    phoneNumber: "",
    message: ""
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  async submitForm() {
    const { name, email, phoneNumber, message } = this.state

    let body = new FormData()
    body.append('name', name)
    body.append('email', email)
    body.append('phone_number', phoneNumber)
    body.append('message', message)

    // Post form data to contact form API
  }

  render() {

    const {
      name,
      email, 
      phoneNumber,
      message
    } = this.state

    return (
      <>
        <Helmet> 
          <title>Contact</title>
        </Helmet>

        <p style={{ fontFamily: "Roboto Slab", fontWeight: 400, fontSize: 10, color: "#777", textTransform: "uppercase", marginTop: 20, marginBottom: 20 }}>
          <Link to="/">HOME</Link> / <Link to="/contact">CONTACT</Link>
        </p>

        <h1 style={{ fontSize: 32, textTransform: "uppercase", marginTop: 60, marginBottom: 65, textAlign: "center", fontFamily: "Lato", fontWeight: 900 }}>Get in touch</h1>

        <div className="row">
          <div className="col-lg-5" style={{ fontSize: 15 }}>
            <div style={{ fontFamily: "Montserrat", fontSize: 11, fontWeight: 400, color: "#333", textTransform: "uppercase", marginBottom: 30 }}>Send Us A Message</div>
            <input className="form-control-plaintext" value={name} onChange={e => this.setState({ name: e.target.value })} placeholder="Your Name" style={{ border: "1px solid #bbb", borderRadius: 5, padding: "11px 10px", marginBottom: 18 }}/>
            <input className="form-control-plaintext" value={email} onChange={e => this.setState({ email: e.target.value })} placeholder="Your E-mail" style={{ border: "1px solid #bbb", borderRadius: 5, padding: "11px 10px", marginBottom: 18 }}/>
            <input className="form-control-plaintext" value={phoneNumber} onChange={e => this.setState({ phoneNumber: e.target.value })} placeholder="Your Phone Number" style={{ border: "1px solid #bbb", borderRadius: 5, padding: "11px 10px", marginBottom: 18 }}/>
            <textarea className="form-control-plaintext" value={message} onChange={e => this.setState({ message: e.target.value })} placeholder="Your Message..." style={{ border: "1px solid #bbb", borderRadius: 5, padding: "11px 10px", marginBottom: 18 }}/>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button className="btn btn-dark btn-lg" onClick={() => this.submitForm()} style={{ background: "#222", fontFamily: "Montserrat", textTransform: "uppercase", fontSize: 13, padding: 14, width: 170, border: "none", marginBottom: 35 }}>Send Message</button>
            </div>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-6">
            <StaticContent title="Contact Info" className="bersi" />
          </div>
        </div>
      </>
    )
  }
}

export default Contact