import React, { Component } from "react"
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'
import "../styles/toast.css"

import Header from "./Header/Header"
import Footer from "./Footer/Footer"

class Layout extends Component {
  render() {
    return(
      <>
        <Header />
        <div className="container mt-4" style={{ minHeight: "50vh" }}>
          {this.props.children}
        </div>
        <ToastContainer 
          position={toast.POSITION.TOP_CENTER}
        />
        <Footer />
      </>
    )
  }
}

export default Layout