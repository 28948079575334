import React, { Component } from "react";
import { withRouter, Redirect } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"
import { toast } from "react-toastify"
import { RadioGroup, Radio } from 'react-radio-group'
import { getCode, getName } from 'country-list'

import { SITE_ID, BASE_URL, LOGISTIX_BASE_URL, ACCESS_TOKEN } from "../../services/api";
import { SIRCLO_WITH_QUEUE_ORDER } from "../../configs"
import { clearCart, purgeUniqueCode } from "../../actions"
import { itemListType } from "../../reducers";
import { Currency } from "../../utils/formatMoney"
import { titleCase } from "../../utils/titleCase"
import { getUniqueCode } from "../../utils/getUniqueCode";
import { isAuth } from "../../utils/isAuth"

import CustomInputLarger from "../../components/CustomInputLarger";
import "./checkout.css";


type CheckoutFormPropType = {
  history: any;
  cart: itemListType[];
  auth: any;
  clearCart: any;
  purgeUniqueCode: any;
  location: any;
}

type CheckoutFormStateType = {
  name: string;
  mobilePhone: string;
  address: string;
  country: string;
  province: string;
  city: string;
  postalCode: string;
  email: string;

  billingEmail: string,
  billingName: string,
  billingMobilePhone: string,
  billingAddress: string,
  billingCountry: string,
  billingProvince: string,
  billingCity: string,
  billingPostalCode: string,

  selectedPaymentMethod: string;
  selectedShippingMethod: string;
  shippingCost: Number;
  countryDropdown: [];
  provinceDropdown: []
  cityDropdown: [];
  rawProvinceData: [];
  paymentMethods: [];
  shippingMethods: [];
  couponCode: string;
  discount: Number;
  sendToDifferentAddress: Boolean;
  typing: Boolean;
  typingTimeout: any;
  placeOrderEnabled: Boolean;
}

class CheckoutForm extends Component<CheckoutFormPropType, CheckoutFormStateType> {

  state = {
    email: "",
    name: "",
    mobilePhone: "",
    address: "",
    country: "",
    province: "",
    city: "",
    postalCode: "",

    billingEmail: "",
    billingName: "",
    billingMobilePhone: "",
    billingAddress: "",
    billingCountry: "",
    billingProvince: "",
    billingCity: "",
    billingPostalCode: "",

    countryDropdown: [] as any,
    provinceDropdown: [] as any,
    cityDropdown: [] as any,
    rawProvinceData: [] as any,
    paymentMethods: [] as any,
    shippingMethods: [] as any,
    selectedPaymentMethod: "",
    selectedShippingMethod: "",
    shippingCost: 0,
    couponCode: "",
    discount: 0,
    sendToDifferentAddress: true,

    typing: false,
    typingTimeout: 0,
    placeOrderEnabled: true
  }

  async componentDidMount() {

    if (this.props.location.state) {
      const { 
        address, 
        city, 
        country, 
        email, 
        mobilePhone, 
        name, 
        postalCode, 
        province 
      } = this.props.location.state
      
      this.setState({
        billingEmail: email,
        billingAddress: address,
        billingCity: city,
        billingMobilePhone: mobilePhone,
        billingName: name,
        billingPostalCode: postalCode,
        billingProvince: province,
        billingCountry: country
      })
    }

    try {
      fetch(`${BASE_URL}payment_methods`, {
        method: "GET",
        headers: {
          "X-SIRCLO-ACCESS-TOKEN": "shop_access",
        },
      }).then(async (res) => {
        const resJSON = await res.json()
        this.setState({ paymentMethods: resJSON.payment_methods })
      })

      let result = await fetch(`${LOGISTIX_BASE_URL}v1/location/countries/srcl`);
      if (result.ok) {
        this.setState({ countryDropdown: (await result.json()).locations})
      } else {
        throw new Error(result.statusText)
      }
      let detailedResult = await fetch(`${LOGISTIX_BASE_URL}v1/location/srcl`);
      let detailedResultJSON = await detailedResult.json();
      
      let rawProvinceData = detailedResultJSON.locations[0].provinces;
      let provinceList = detailedResultJSON.locations[0].provinces.map(province => province.provider_data);
      if (detailedResult.ok) {
        this.setState({ provinceDropdown: provinceList, rawProvinceData })
      } else {
        throw new Error(result.statusText)
      }
    } catch(err) {
      console.warn(err);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { province, billingProvince, rawProvinceData, selectedShippingMethod, selectedPaymentMethod, country, city, couponCode } = this.state;
    
    var cityDistricts = [] as any;
    if (prevState.province !== province || prevState.billingProvince !== billingProvince) {
      rawProvinceData.forEach(state => {
        if (state.provider_data == province || state.provider_data == billingProvince) {
          state.cities.forEach(city => {
              city.districts.forEach(district => {
                  cityDistricts.push(district.provider_data);
              });
          });
        }
      });
      this.setState({ cityDropdown: cityDistricts })
    }

    if (prevState.selectedShippingMethod !== selectedShippingMethod || 
      prevState.selectedPaymentMethod !== selectedPaymentMethod ||
      prevState.couponCode !== couponCode) {
      let body = JSON.stringify({
        "items": this.props.cart.map(item => {
          return {
            "fid": item.productCode,
            quantity: item.qty
          }
        }),
        "shipping_method": selectedShippingMethod,
        "shipping_country": country,
        "shipping_city": city,
        "payment_method": selectedPaymentMethod,
        "coupon_code": couponCode,
        "lgx_area": "true",
      })
      let fetchCalculateResult = await fetch(`${BASE_URL}cart`, {
        method: "POST",
        headers: {
          "X-SIRCLO-ACCESS-TOKEN": "shop_access",
          "X-SIRCLO-SESSION-ID": this.props.auth.sessionId,
        },
        body
      })
      if (fetchCalculateResult.ok) {
        let resultJSON = await fetchCalculateResult.json();
        if (resultJSON.status === 200) {
          this.setState({ shippingCost: resultJSON.result.shipping, discount: resultJSON.result.discounts[0] && resultJSON.result.discounts[0].value })
        } else {
          // Handle error disini
        }
      }
    }
  }

  onChangeDiscount(couponCode) {
    const self = this

    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout)
    }

    this.setState({
      typing: false,
      typingTimeout: setTimeout(function() {
        self.setState({ couponCode })
      }, 2000)
    })

  }

  onChangeCity(city) {
    const self = this
    this.setState({ city })

    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout)
    }

    this.setState({
      typing: false,
      typingTimeout: setTimeout(function() {
        fetch(`${BASE_URL}shipping_methods?country=ID&city=${city}&lgx_area=true`, {
          method: "GET",
          headers: {
            "X-SIRCLO-ACCESS-TOKEN": "shop_access",
          },
        }).then(async (res) => {
          const resJSON = await res.json()
          self.setState({ shippingMethods: resJSON.shipping_methods })
        })
      }, 3500)
    })
  }

  async onSubmitForm() {
    const {
      email,
      name,
      mobilePhone,
      address,
      country,
      province,
      city,
      postalCode,
      
      billingEmail,
      billingName,
      billingMobilePhone,
      billingAddress,
      billingCountry,
      billingProvince,
      billingCity,
      billingPostalCode,

      selectedShippingMethod,
      selectedPaymentMethod,
    } = this.state;

    if (!email || !name || !mobilePhone || !address || !country || !province || !city
        || !postalCode || !selectedPaymentMethod || !selectedShippingMethod) {
          toast.error("Checkout gagal! Form checkout belum lengkap terisi.")
          return
    }

    let body = JSON.stringify({
      "billing-info": {
        "salutation": "Mr",
        "full-name": billingName,
        "address-line1": billingAddress,
        "district": billingCity,
        "city": billingCity,
        "province": billingProvince.toUpperCase(),
        "country": billingCountry,
        "phone-number": billingMobilePhone.toString(),
        "email": billingEmail,
        "postal-code": parseInt(billingPostalCode)
      },
      "shipping-info": {
        "method": selectedShippingMethod,
        "cost": 9000
      },
      "shipping-address": {
        "same-with-billing-info": false,
        "full-name": name,
        "address-line1": address,
        "district": city,
        "city": city,
        "province": province.toUpperCase(),
        country,
        "phone-number": mobilePhone.toString(),
        email,
        "postal-code": parseInt(postalCode)
      },
      "items": this.props.cart.map(item => {
        return {
          "product-code": item.productCode,
          quantity: item.qty
        }
      }),
      "payments": {
        "method": selectedPaymentMethod,
        "unique_code": getUniqueCode() // Get unique code from Redux
      },
      "buyers-message": "string",
      "order-date": "string",
      "order_source": "Wisnu",
      "lgx_area": "true"
    });

    this.setState({ placeOrderEnabled: false })

    try {
      if (SIRCLO_WITH_QUEUE_ORDER) {
        // ----------------------------------
        // ORDER QUEUED CHECKOUT (CALO)
        // ----------------------------------
        let result = await fetch(`${BASE_URL}orders/calo`, {
          method: "POST", // POST
          headers: {
            "X-SIRCLO-ACCESS-TOKEN": ACCESS_TOKEN,
          },
          body
        })
        if (result.ok) {
          result.json().then(data => {
            if (data.status == 200) {

              // Poll to calo and wait for intended result
              toast.warn('Your order is being processed. Please do not leave this page.', {
                autoClose: 10000,
                pauseOnHover: false
              })
              console.log(data)
              
              const self = this
              let caloPolling = setInterval(function() {

                // Keep polling Calo until order is finished
                fetch(`https://calo.sirclo.com.dmmy.me/peek?shop_id=${SITE_ID}&cart_id=${data.cart_code}`, {
                  method: "GET",
                  headers: {
                    "X-SIRCLO-ACCESS-TOKEN": ACCESS_TOKEN,
                  }
                })
                .then((data) => data.json())
                .then(data => {

                  try {
                    if (data["message"].status === "done" && data["message"].response.status_code == 301) {

                      // POP the calo when the order is finished
                      fetch(`https://calo.sirclo.com.dmmy.me/pop?shop_id=${data["message"].shop_id}&cart_id=${data["message"].cart_id}`, {
                        method: "GET",
                        headers: {
                          "X-SIRCLO-ACCESS-TOKEN": ACCESS_TOKEN,
                        }
                      })
                      .then((data) => data.json())
                      .then(data => {
                        if (data.status == 200) {
                          self.props.clearCart()
                          self.props.purgeUniqueCode()
                          self.props.history.push(`/payment_notif?payment_method=bank-transfer&total_amount=${data["total_amount"]}&order_id=${data["long_id"]}&order_email=${email}`)
                        } else {
                          toast.error(data["message"])
                        }

                      })
                    }
                  }
                  catch (err) {
                    console.warn(`Order failed. Please try again. ${err}`)
                    self.setState({ placeOrderEnabled: true })
                  }
                })

              }, 1000)

              setTimeout(function() {
                // After 20 seconds is Calo stopped responding, cancel Polling
                toast.warn('Please wait while your order is in queue. Due to high traffic, this may take a moment.', {
                  autoClose: 10000,
                  pauseOnHover: false
                })
              }, 10000)

              setTimeout(function() {
                // After 20 seconds is Calo stopped responding, cancel Polling
                clearInterval(caloPolling)
                toast.error('Order failed. Please try again.')
                self.setState({ placeOrderEnabled: true })
              }, 20000)

            } else {
              toast.error(data.message)
            }
          })
        }
      } else {
        // ----------------------------------
        // SIMPLE INSTANT CHECKOUT
        // ----------------------------------
        let result = await fetch(`${BASE_URL}orders`, {
          method: "POST",
            headers: {
              "X-SIRCLO-ACCESS-TOKEN": ACCESS_TOKEN,
              "X-SIRCLO-SESSION-ID": this.props.auth.sessionId,
            },
            body
        })
        if (result.ok) {
          result.json().then(data => {
            if (data.status == 200) {
              this.props.clearCart()
              this.props.purgeUniqueCode()
              this.props.history.push(`/payment_notif?payment_method=bank-transfer&total_amount=${data.total_amount}&order_id=${data.long_id}&order_email=${email}`)
            } else {
              toast.error(`Checkout Gagal: ${data.message.replace('<br />', '')}`)
              this.setState({ placeOrderEnabled: true })
            }
          })
        }
      }
    }
    catch(err) {
      toast.error(`Terjadi kesalahan saat checkout ➜ ${err}`)
      this.setState({ placeOrderEnabled: true })
    }
  }

  render() {
    const { 
      email, 
      country, 
      city, 
      name, 
      mobilePhone, 
      address, 
      province, 
      postalCode, 

      billingEmail,
      billingCountry,
      billingCity,
      billingName,
      billingMobilePhone,
      billingAddress,
      billingProvince,
      billingPostalCode,

      countryDropdown, 
      provinceDropdown, 
      cityDropdown,
      paymentMethods,
      shippingMethods,
      selectedShippingMethod,
      selectedPaymentMethod,
      shippingCost,
      discount,
      sendToDifferentAddress
    } = this.state;  
    let totalPrice = 0;

    return (
      <>
      <Helmet>
        <title>Checkout</title>
      </Helmet>
      <h1 style={{ fontSize: 32, textTransform: "uppercase", marginTop: 60, marginBottom: 65, textAlign: "center", fontFamily: "Lato", fontWeight: 900 }}>CHECKOUT</h1>
      <div className="row">
        <div className="col-lg-8">
        {/* <form onSubmit={this.onSubmitForm}> */}
          <CustomInputLarger onChange={(e) => this.setState({ billingName: e.target.value })} value={billingName} type="text" label="Name"/>
          <CustomInputLarger onChange={(e) => this.setState({ billingMobilePhone: e.target.value })} value={billingMobilePhone} type="text" label="Mobile Phone"/>
          <CustomInputLarger onChange={(e) => this.setState({ billingAddress: e.target.value })} value={billingAddress} type="text" label="Address"/>
          <CustomInputLarger onChange={(e) => this.setState({ billingCountry: e.target.value })} label="Country" type="dropdown" value={billingCountry}>
            {
              countryDropdown.map((country, index) => {
                return (
                  <option key={index} value={country.location_code}>
                    {country.provider_data}
                  </option>
                )
              })
            }
          </CustomInputLarger>
          <CustomInputLarger onChange={(e) => this.setState({ billingProvince: e.target.value})} suggestions={billingCountry === "ID" ? provinceDropdown : []} value={billingProvince} type="text-autosuggest" label="State/Province"/>
          <CustomInputLarger onChange={(e) => this.onChangeCity(e.target.value)} suggestions={cityDropdown} value={billingCity} type="text-autosuggest" label="City/District"/>
          <CustomInputLarger onChange={(e) => this.setState({ billingPostalCode: e.target.value })} value={billingPostalCode} type="number" label="Postal Code"/>
          <CustomInputLarger onChange={(e) => this.setState({ billingEmail: e.target.value })} value={billingEmail} type="email" label="Email"/>

          <p style={{ marginTop: 20, fontFamily: "Lato", fontSize: 18, fontWeight: 400, color: "#333" }}>Shipping Address</p>
          <input type="checkbox" checked={sendToDifferentAddress} onChange={(e) => this.setState({ sendToDifferentAddress: !sendToDifferentAddress })}></input><label style={{ fontFamily: "Roboto Slab", fontSize: 12, color: "#777", marginLeft: 10 }}>Send to different address?</label>

          { 
            !sendToDifferentAddress ? (
              <Redirect 
                to={{
                  pathname: "/checkout/place_order",
                  state: {
                    billingName,
                    billingMobilePhone,
                    billingAddress,
                    billingCountry,
                    billingProvince,
                    billingCity,
                    billingPostalCode,
                    billingEmail
                  }
                }}
              />
            ) :
              <div className="mt-3">
                <CustomInputLarger onChange={(e) => this.setState({ name: e.target.value })} value={name} type="text" label="Name"/>
                <CustomInputLarger onChange={(e) => this.setState({ mobilePhone: e.target.value })} value={mobilePhone} type="text" label="Mobile Phone"/>
                <CustomInputLarger onChange={(e) => this.setState({ address: e.target.value })} value={address} type="text" label="Address"/>
                <CustomInputLarger onChange={e => this.setState({ country: e.target.value })} label="Country" type="dropdown" value={country}>
                  {
                    countryDropdown.map((country, index) => {
                      return (
                        <option key={index} value={country.location_code}>
                          {country.provider_data}
                        </option>
                      )
                    })
                  }
                </CustomInputLarger>
                <CustomInputLarger onChange={(e) => this.setState({ province: e.target.value})} suggestions={country === "ID" ? provinceDropdown: []} value={province} type="text-autosuggest" label="State/Province"/>
                <CustomInputLarger onChange={(e) => this.onChangeCity(e.target.value)} suggestions={cityDropdown} value={city} type="text-autosuggest" label="City/District"/>
                <CustomInputLarger onChange={(e) => this.setState({ postalCode: e.target.value })} value={postalCode} type="number" label="Postal Code"/>
                <CustomInputLarger onChange={(e) => this.setState({ email: e.target.value })} value={email} type="email" label="Email"/>
              </div>
          }

          <p style={{ marginTop: 20, fontFamily: "Lato", fontSize: 18, fontWeight: 400, color: "#333" }}>Payment Method</p>
          <RadioGroup name="payment_method" selectedValue={selectedPaymentMethod} onChange={value => this.setState({ selectedPaymentMethod: value })}>
            {paymentMethods.map((met, index) => <label style={{ display: "block", fontFamily: "Roboto Slab", fontSize: 12, color: "#777" }} key={index}><Radio style={{ marginRight: 10 }} value={met.value} />{met.title}</label> )}
          </RadioGroup>

          <p style={{ marginTop: 15, fontFamily: "Lato", fontSize: 18, fontWeight: 400, color: "#333" }}>Shipping Method</p>
          <RadioGroup name="shipping_method" selectedValue={selectedShippingMethod} onChange={value => this.setState({ selectedShippingMethod: value })}>
            {shippingMethods.map((ship, index) => <label style={{ display: "block", fontFamily: "Roboto Slab", fontSize: 12, color: "#777" }} key={index}><Radio style={{ marginRight: 10 }} value={ship} /> {ship}</label>)}
          </RadioGroup>

          <CustomInputLarger onChange={(e) => this.onChangeDiscount(e.target.value)} type="text" label="Discount Code"/>

          <small id="emailHelp" className="form-text text-muted" style={{ marginBottom: 20, marginTop: 20 }}>Fields marked with <span style={{ color: "#ffa500", marginLeft: 3 }}>*</span> are required.</small>
          <button onClick={() => this.onSubmitForm()} disabled={!this.state.placeOrderEnabled} className="btn btn-dark btn-lg" style={{ background: "#222", fontFamily: "Montserrat", textTransform: "uppercase", fontSize: 13, padding: 14, width: 170, border: "none", marginBottom: 35 }} >
            {this.state.placeOrderEnabled ? "Place Order" : "Placing Order..."}
          </button>
        {/* </form> */}
        </div>

          <div className="col-lg-4">
            <div style={{ position: "sticky", top: 15 }}>
            <div className="checkout__summary-header">
              ORDER SUMMARY
            </div>
            <div className="checkout__summary-content">
              <table>
                <tbody>
                  {
                    this.props.cart.map((item, index) => {
                      totalPrice += (item.qty * item.price)
                      return (
                        <tr key={index} style={{ padding: "8px 20px", fontFamily: "Lato", fontWeight: 400, fontSize: 14 }}>
                          <td style={{ width: "80%" }}>
                            {`${item.qty}`} x <span style={{ textTransform: "uppercase" }}><Link to={`/products/${item.id}`}>{`${item.title} ${item.size ? item.size : ""} ${item.color ? item.color : ""}`}</Link></span>
                          </td>
                          <td style={{ textAlign: "right", fontFamily: "Roboto Slab", fontSize: 12, color: "#777" }}>
                            <Currency value={item.price * item.qty}/>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
            <div className="checkout__summary-total">
              <table>
                <tbody>
                  <tr>
                    <td style={{ width: "80%" }}>Item Total</td>
                    <td style={{ alignSelf: "flex-end", textAlign: "right" }}>
                      <Currency value={totalPrice}/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {
              discount ? (
                <div className="checkout__summary-grandtotal">
                  <table>
                    <tbody>
                      <tr style={{ padding: "8px 20px", fontFamily: "Lato", fontWeight: 400, fontSize: 14 }}>
                        <td style={{ width: "80%" }}>
                          <span style={{fontFamily: "Roboto Slab", fontWeight: 400, fontSize: 12, color: "#777"}}>
                            Discount
                          </span>
                        </td>
                        <td style={{ textAlign: "right", fontFamily: "Roboto Slab", fontSize: 12, color: "#777" }}>
                          -<Currency value={Math.abs(discount)}/>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                null
              )
            }
            <div className="checkout__summary-grandtotal">
              <table>
                <tbody>
                  <tr style={{ padding: "8px 20px", fontFamily: "Lato", fontWeight: 400, fontSize: 14 }}>
                    <td style={{ width: "80%" }}>
                      <span style={{fontFamily: "Roboto Slab", fontWeight: 400, fontSize: 12, color: "#777"}}>
                        Unique Code
                      </span>
                    </td>
                    <td style={{ textAlign: "right", fontFamily: "Roboto Slab", fontSize: 12, color: "#777" }}>
                      <Currency value={getUniqueCode()}/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {
              selectedShippingMethod ? (
                <div className="checkout__summary-grandtotal">
                  <table>
                    <tbody>
                      <tr style={{ padding: "8px 20px", fontFamily: "Lato", fontWeight: 400, fontSize: 14 }}>
                        <td style={{ width: "80%" }}>
                          <span style={{fontFamily: "Roboto Slab", fontWeight: 400, fontSize: 12, color: "#777"}}>
                            Shipping and Handling {selectedShippingMethod ? `(${country} - ${titleCase(city.split('-').join(' - '))} - ${selectedShippingMethod})` : null }
                          </span>
                      </td>
                        <td style={{ textAlign: "right", fontFamily: "Roboto Slab", fontSize: 12, color: "#777" }}>
                          {<Currency value={shippingCost}/>}
                        </td>
                      </tr>
                      <tr style={{ padding: "8px 20px", fontFamily: "Lato", fontWeight: 400, fontSize: 14 }}>
                        <td style={{ width: "80%" }}>
                          <span style={{fontFamily: "Roboto Slab", fontWeight: 700, fontSize: 16, color: "#777"}}>Total:</span>
                        </td>
                        <td style={{ paddingTop: 20, textAlign: "right", fontFamily: "Roboto Slab", fontSize: 12, color: "#777" }}>
                          <span style={{fontFamily: "Roboto Slab", fontWeight: 700, fontSize: 16, color: "#777"}}>
                            {discount ? <Currency value={totalPrice + shippingCost + discount + getUniqueCode()} /> : <Currency value={totalPrice + shippingCost + getUniqueCode()} />}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                null
              )
            }
          </div>
        </div>
      </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    cart: state.cart.itemList,
    auth: state.auth
  }
}

const mapDispatchToProps = {
  clearCart,
  purgeUniqueCode
}

export default withRouter<any>(connect(mapStateToProps, mapDispatchToProps)(CheckoutForm));