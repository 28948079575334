import { 
  ADD_TO_CART, 
  REMOVE_ITEM, 
  CHANGE_QTY, 
  ADD_QTY, 
  CLEAR_CART, 
  LOGIN_SUCCESS, 
  LOGOUT,
  GENERATE_UNIQUE_CODE,
  PURGE_UNIQUE_CODE } from "../actions"

export type itemListType = {
  id: string; 
  title: string;
  productCode: string;
  image: string;
  qty: number;
  price: number;
  color: string;
  size: string;
  fid: string;
}

export type cartStateType =  {
  itemList: itemListType[];
}

const initialState: cartStateType = { itemList: [] };

export function cart(state = initialState, action: any) {
  let newItemList: itemListType[] = []
  switch(action.type) {
    case ADD_TO_CART:
      return {
        itemList: [
          ...state.itemList, 
          action.payload,
        ]
      }
    case REMOVE_ITEM:
      newItemList = state.itemList.filter(item => action.payload.productCode !== item["productCode"])
      return { itemList: newItemList };
    case ADD_QTY:
      return {
        ...state,
        itemList: state.itemList.map(item => action.payload.productCode == item["productCode"] ?
          { ...(item as object), qty: item["qty"] + parseInt(action.payload.qty) } :
          item
        )
      }
    case CHANGE_QTY:
      newItemList = state.itemList.map(item => item.productCode === action.payload.productCode ? (
        { ...item, qty: parseInt(`${action.payload.qty}`) }
      ) : item )
      return { itemList: newItemList };
    case CLEAR_CART:
      return { itemList: [] }
    default:
      return state;
  }
}

const initialAuthState = {
  email: "",
  sessionId: ""
}

export function auth(state = initialAuthState, action) {
  switch(action.type) {
    case LOGIN_SUCCESS:
      return { email: action.payload.email, sessionId: action.payload.sessionId };
    case LOGOUT: 
      return initialAuthState;
    default:
      return state;
  }
}

const initialUniqueCodeState = {
  uniqueCode: ""
}

export function uniqueCode(state = initialUniqueCodeState, action) {
  switch(action.type) {
    case GENERATE_UNIQUE_CODE:
      return { uniqueCode: action.payload.uniqueCode }
    case PURGE_UNIQUE_CODE:
      return initialUniqueCodeState
    default:
      return state
  }
}