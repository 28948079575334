import React, { Component } from "react"
import { connect } from "react-redux"
import { Helmet } from "react-helmet"
import { FormattedMessage, FormattedNumber } from "react-intl"
import { Link } from "react-router-dom"
import { removeItem, changeQuantity } from "../actions"
import { Currency } from "../utils/formatMoney"

class Cart extends Component<any, any> {

  componentDidMount() {
    window.scrollTo(0, 0)
  }
  
  changeQty = (id, qty) => {
    if (/^[0-9]*$/.test(qty)) {

      if (qty == 0) {
        this.props.removeItem(id)
        window.location.reload()
      }

      if (qty > 0) {
        this.props.changeQuantity(id, qty)
      }
    }
  }

  getCartTotal() {
    let cartTotal = 0
    
    if (this.props.items.length) {
      this.props.items.forEach(item => {
        cartTotal += item.qty * item.price
      }) 
      return cartTotal
    }
    return 0
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Shopping Cart</title>
        </Helmet>

        <p style={{ fontFamily: "Roboto Slab", fontWeight: 400, fontSize: 10, color: "#777", textTransform: "uppercase", marginTop: 20, marginBottom: 20 }}>
          <Link to="/">HOME</Link> / <Link to="#">SHOPPING CART</Link>
        </p>

        <h1 style={{ fontSize: 32, textTransform: "uppercase", marginTop: 60, marginBottom: 65, textAlign: "center", fontFamily: "Lato", fontWeight: 900 }}>YOUR CART</h1>

        <table className="table">
          <thead style={{ fontFamily: "Lato", fontWeight: 700, fontSize: 11, color: "#999", textTransform: "uppercase", background: "#e1e1e1", textAlign: "right", borderRight: "1px solid #e1e1e1" }}>
            <tr>
              <th scope="col" style={{ textAlign: "left", paddingLeft: 20 }}>Picture</th>
              <th scope="col" style={{ textAlign: "left", paddingLeft: 20 }}>Description</th>
              <th scope="col" style={{ paddingRight: 20 }}>Price</th>
              <th scope="col" style={{ paddingRight: 20 }}>Quantity</th>
              <th style={{ paddingRight: 20 }}>Amount</th>
            </tr>
          </thead>
          <tbody>
            {
              this.props.items.length > 0 ?
              (
                this.props.items.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td style={{ width: 76, padding: 20, borderRight: "1px solid #e4e4e4", borderLeft: "1px solid #e4e4e4" }}><img className="img-fluid" style={{ maxWidth: 75 }} src={`//thumbor.sirclocdn.xyz/unsafe/filters:quality(25)/https:${item.image}`} alt={`Image of ${item.title}`} /></td>
                      <td style={{ padding: 20, fontFamily: "Lato", fontWeight: 400, verticalAlign: "middle", borderRight: "1px solid #e4e4e4" }}>
                        <p style={{ fontSize: 14, textTransform: "uppercase" }}>
                          <Link to={`/products/${item.id}`}>{item.title}</Link>
                          <span style={{ textTransform: "none" }}>
                            {item.color ? (<><br /><strong>Color:</strong> {item.color}</>) : null} 
                            {item.size ? (<><br /><strong>Size:</strong> {item.size}</>) : null} 
                          </span>
                        </p>
                      </td>
                      <td style={{ padding: 20, fontFamily: "Roboto Slab", verticalAlign: "middle", borderRight: "1px solid #e4e4e4", width: 130 }}>
                        <p style={{ fontSize: 12, textAlign: "right" }}>
                          <Currency value={item.price}/>
                        </p>
                      </td>
                      <td style={{ padding: 20, verticalAlign: "middle", borderRight: "1px solid #e4e4e4", width: 64, textAlign: "center" }}>
                        <input onChange={(e) => this.changeQty(item.productCode, parseInt(e.target.value))} className="form-control" style={{ width: 64, background: "#f7f7f7", textAlign: "center", fontSize: 12 }} type="text" defaultValue={item.qty} />
                        <a style={{ fontFamily: "Helvetica", fontSize: 9, textAlign: "center", cursor: "pointer" }} onClick={() => {this.props.removeItem(item.productCode); window.location.reload()}}>REMOVE</a>  
                      </td>
                      <td style={{ padding: 20, fontFamily: "Roboto Slab", verticalAlign: "middle",  borderRight: "1px solid #e4e4e4", width: 130 }}>
                        <p style={{ fontSize: 12, textAlign: "right" }}>
                          <Currency value={item.qty * item.price} />
                        </p>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr><td>
                  <p style={{ fontFamily: "Roboto Slab", fontSize: 13, color: "#777", marginTop: 15 }}>
                    <FormattedMessage 
                      id="app.cartIsEmpty"
                      defaultMessage="You have not added any item."
                    />
                  </p>
                </td></tr>
              )
            }
            <tr style={{ borderTop: "1px solid black" }}>
              <td style={{ padding: ".2rem" }}></td>
              <td style={{ padding: ".2rem" }}></td>
              <td style={{ padding: ".2rem" }}></td>
              <td style={{ padding: ".2rem" }}></td>
              <td style={{ padding: ".2rem" }}></td>
            </tr>
            <tr>
              <td style={{ border: "none" }}></td>
              <td style={{ border: "none" }}></td>
              <td style={{ border: "none" }}></td>
              <td style={{ border: "none",fontFamily: "Roboto Slab", fontWeight: 700, fontSize: 12, textAlign: "right" }}>Item Total:</td>
          <td style={{ border: "none",fontFamily: "Roboto Slab", fontWeight: 700, fontSize: 12, textAlign: "right" }}>{<Currency value={this.getCartTotal()} />}</td>
            </tr>
          </tbody>
        </table>

        <div className="text-right mb-5" style={{ fontFamily: "Lato", fontWeight: 700, fontSize: 11, textTransform: "uppercase" }}>
          <Link to={`/products/all`}>Continue Shopping?</Link>
          {
            this.props.items.length > 0 ?
              <>
                <span style={{ fontWeight: 400, paddingLeft: 10, paddingRight: 10 }}>OR</span>
                <button className="btn btn-dark btn-lg" onClick={() => this.props.history.push("/checkout")} style={{ background: "#222", fontFamily: "Lato", fontWeight: 700, textTransform: "uppercase", fontSize: 11, padding: "15px 25px", border: "none" }}>Checkout</button>
              </>
            :
              null
          }
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    items: state.cart.itemList
  }
}

const mapDispatchToProps = {
  removeItem,
  changeQuantity
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart)