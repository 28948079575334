import React, { Component } from "react";
import AutoSuggest from "./AutoSuggest/AutoSuggest";

export type CustomInputLargerTypes = "text" | "text-autosuggest" | "date" | "number" | "password" | "dropdown" | "email";

export type CustomInputLargerPropTypes = {
  type: CustomInputLargerTypes;
  label: string;
  placeholder?: string;
  value?: any;
  onChange?: (event: any) => void; 
  children?: any;
  suggestions?: any;
  required?: boolean;
}

export default function CustomInputLarger({type, label, placeholder, value, onChange, children, suggestions, required = false}: CustomInputLargerPropTypes) {
  let itemToRender = <> </>;
  switch (type) {
    case "dropdown":
      itemToRender = (
        <select onChange={onChange} style={{ width: "100%", height: "100%", background: "none", borderRadius: 5, border: "1px solid #999", padding: "14px 10px" }} value={value}>
          {children}
        </select>
      )
      break;
    case "text-autosuggest":
      itemToRender = (
        <AutoSuggest onChangeProp={onChange} suggestions={suggestions} value={value} />
      )
      break;
    default:
      itemToRender = (
        <input onChange={event => onChange!(event)} value={value} type={type} className="form-control-plaintext" placeholder={placeholder} style={{ border: "1px solid #bbb", borderRadius: 5, padding: "14px 10px" }}/>
      )
  }
  return (
    <div className="form-group row">
      <label className="col-sm-2 col-form-label" style={{ paddingTop: 15, fontWeight: 700, color: "#777", fontFamily: "Roboto Slab", fontSize: 12 }}>{label}{required ? <span style={{ color: "#ffa500", marginLeft: 3 }}>*</span> : ""}</label>
      <div className="col-sm-8">
        {itemToRender}
      </div>
    </div>
  );
}
