import React, { Component } from "react"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"
import { toast } from "react-toastify"
import { BASE_URL, ACCESS_TOKEN } from "../../services/api"
import { login } from "../../actions"

type AccountLoginPropType = {
  history: any;
  login: any;
  auth: any;
}

type AccountLoginStateType = {
  username: string;
  password: string;
}

class AccountLogin extends Component<AccountLoginPropType, AccountLoginStateType> {

  state = {
    username: "",
    password: ""
  }

  async onLogin() {
    const { username, password } = this.state;
    
    let body = new FormData();
    body.append("username", username.trim());
    body.append("password", password.trim());
    
    // NOTES: Actual login mechanism
    try {
      let result = await fetch(BASE_URL + "account/login", {
        method: "POST",
        headers: {
          "X-SIRCLO-ACCESS-TOKEN": ACCESS_TOKEN,
        },
        body,
        mode: 'cors',
        cache: 'default'
      })
      if (result.ok) {
        result.json().then(data => {
          if (data.status == 200) {
            this.props.login(username, data.session_id)
            window.location.replace("/account")
          } else {
            toast.error("Failed to login: " + data.message)
          }
        })
      }
    } 
    catch(err) {
      toast.error("Terjadi Kesalahan saat Login ➜ " + err)
    }

    // NOTES: The following code is used to test account details API in a quick way
    
    // fetch(`login`, {
    //   method: "POST",
    //   headers: {
    //     "X-SIRCLO-ACCESS-TOKEN": ACCESS_TOKEN,
    //   },
    //   body,
    //   credentials: "include",
    //   mode: 'cors',
    //   cache: 'default'
    // })
    // .then(async (res) => {
    //   const resJSON = await res.json()
    //   console.log(resJSON)
    //   const result = await fetch(`details`, {
    //     method: "GET",
    //     headers: {
    //       "X-SIRCLO-ACCESS-TOKEN": ACCESS_TOKEN,
    //     },
    //     credentials: 'include'
    //   })
    //   console.log(await result.json())
    // })
  }

  render() {
    return (
      <>
        <Helmet> 
          <title>Login</title>
        </Helmet>
        <p style={{ fontFamily: "Roboto Slab", fontWeight: 400, fontSize: 10, color: "#777", textTransform: "uppercase", marginTop: 20, marginBottom: 20 }}>
          <Link to="/">HOME</Link> / <Link to="#">LOGIN</Link>
        </p>
        <div style={{ margin: "auto auto 30px", width: 320, height: 411, background: "#EEEEEE", padding: 30, boxShadow: "0 0 10px 2px #ddd" }}>
          <h2 style={{ margin: "42px 0px 20px", fontFamily: "Lato", fontWeight: 900, fontSize: 24 }}>LOGIN</h2>
          <br/>
          <input onChange={(e) => this.setState({ username: e.target.value })} style={{ fontFamily: "Helvetica", fontSize: 15, border: "1px solid #ccc", width: 260, height: 37, marginBottom: 18, borderRadius: 5, padding: "9px 10px" }} name="email" type="text" placeholder="E-mail Address"/>
          <br/>
          <input onChange={(e) => this.setState({ password: e.target.value })} style={{ fontFamily: "Helvetica", fontSize: 15, border: "1px solid #ccc", width: 260, height: 37, marginBottom: 18, borderRadius: 5, padding: "9px 10px" }} type="password" placeholder="Password"/>
          <br/>
          <button onClick={() => this.onLogin()} style={{ marginTop: "10px", fontFamily: "Lato", fontWeight: 700, fontSize: 13, border: "none", background: "black", color: "white", borderRadius: 3, width: 260, height: 55 }} >LOGIN</button>
        </div>
        <button onClick={() => this.props.history.push("/account/register")} style={{ display: "block", fontFamily: "Lato", fontWeight: 700, fontSize: 13, border: "none", background: "black", color: "white", borderRadius: 3, margin: "auto auto", width: 320, height: 37, marginBottom: 40 }}>REGISTER</button>
      </>
    );
  }
}

export function mapStateToProps(state) {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = {
  login
}

export default withRouter<any>(connect(mapStateToProps, mapDispatchToProps)(AccountLogin));
