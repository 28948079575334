import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { SIRCLO_STORE_NAME } from "../configs"
import { get } from "../services/api"
import Carousel from "../components/Carousel/Carousel"
import Loading from "../components/Loading"
import StaticContent from "../utils/staticContent/staticContent"
import ProductCard from "../features/products/components/ProductCard";

class Home extends Component<any, any> {
  
  constructor(props: any) {
    super(props)

    this.state = {
      selectedProduct: null,
      data: null,
      carouselImages: null
    }
  }

  componentDidMount() {
    get("products?page=1&items_per_page=5&is_featured=1")
      .then(response => response.json())
      .then(data => this.setState({ data }))

    get("banners")
    .then(response => response.json())
    .then(carouselImages => this.setState({ carouselImages }))
  }

  render() {

    const { data, carouselImages } = this.state

    return (
      <>
        <Helmet>
          <title>{`${SIRCLO_STORE_NAME}`}</title>
        </Helmet>
        <div style={{ marginBottom: 30 }}>
          <Carousel>
            {
              carouselImages ?
                carouselImages.banners.map((image) =>
                  <a key={`carousel-${image.id}`} href={image.link}>
                    <img style={{ width: "100%" }} src={`//thumbor.sirclocdn.xyz/unsafe/filters:quality(25)/https:${image.image.replace(/\.[^/.]+$/, "")}_ori.${image.image.substring(image.image.lastIndexOf(".")+1)}`} />
                  </a>
                )
              :
              <Loading />
            }
          </Carousel>
        </div>
        <h1 className="mb-4" style={{ fontFamily: "Montserrat", fontSize: 11, textTransform: "uppercase" }}>Featured Product</h1>
        {
          data ? 
            <div className="row justify-content-center">
              {data.products.map((product) => 
                <ProductCard 
                  id={product.id}
                  title={product.title}
                  image={product.images[0]}
                  price={product.price_raw}
                  usualPrice={product.usual_price_raw}
                />
              )}
            </div>
          : 
            <Loading />
        }

        <div className="container-fluid" style={{ marginRight: -15, marginLeft: -15 }}>
          <div className="row" style={{ justifyContent: "space-between", borderTop: "3px solid #ededed", paddingTop: 30, paddingBottom: 70 }}>
            <div className="col-lg-4" style={{ paddingLeft: 0, paddingRight: 0, display: "flex", justifyContent: "center" }}><StaticContent title="Widget 1" className="widget-image" /></div>
            <div className="col-lg-4" style={{ paddingLeft: 0, paddingRight: 0, display: "flex", justifyContent: "center" }}><StaticContent title="Widget 2" className="widget-image" /></div>
            <div className="col-lg-4" style={{ paddingLeft: 0, paddingRight: 0, display: "flex", justifyContent: "center" }}><StaticContent title="Widget 3" className="widget-image" /></div>
          </div>
        </div>
      </>
    );
  }
}

export default Home