import React, { Component } from "react"
import { Link } from "react-router-dom";
import Modal from "react-modal"
import { Currency } from "../../../utils/formatMoney";
import ProductDetails from "../ProductDetails";

Modal.setAppElement('#root');

export default class ProductCard extends Component<any, any> {

  constructor(props: any) {
    super(props)

    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
  }

  state = {
    showModal: false,
    selectedProduct: null,
    data: null as any,
    isLoading: true
  }


  handleOpenModal (productId) {
    this.setState({ showModal: true, selectedProduct: productId })
  }
  
  handleCloseModal () {
    this.setState({ showModal: false })
  }

  render() {
    const { id, title, image, price, usualPrice } = this.props
    return (
      <>
        <div className="col mb-4" style={{ display: "inline-flex", justifyContent: "center" }}>
          <div style={{ width: 170 }}>
            <img src={`//thumbor.sirclocdn.xyz/unsafe/filters:quality(25)/https:${image}`} className="card-img-top img-fluid" style={{ background: "#fdfdfd" }} alt={`Image of ${title}`} onClick={() => this.handleOpenModal(id)} />
            <div className="card-body" style={{ fontFamily: "Roboto Slab", overflow: "hidden", padding: 0, marginTop: 8, color: "#333" }}>
              <Link to={`/products/${id}`}>
                <h5 style={{ fontSize: 13, marginBottom: 0 }}>{title}</h5>
              </Link>
              <p className="card-text" style={{ fontSize: 11 }}>
                <>
                  {
                    usualPrice ? (
                      <>
                        <del><Currency value={usualPrice} /></del> <span style={{ color: "red" }}><Currency value={price}/></span>
                      </>
                    ) : (
                      <Currency value={price}/>
                    )
                  }
                </>
              </p>
            </div>
          </div>
        </div>

        <Modal 
          isOpen={this.state.showModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.handleCloseModal}
          shouldCloseOnOverlayClick={true}
        >
          <div style={{ zIndex: 1 }}>
            <ProductDetails
              productId={this.state.selectedProduct}
              closeModal={this.handleCloseModal}
            />
          </div>
        </Modal>
      </>
    )
  }
}