import { SIRCLO_SITE_ID, SIRCLO_SITE_HOST, SIRCLO_WITH_HTTPS } from "../configs"

function getProtocol() {
  if (SIRCLO_WITH_HTTPS == true) {
    return "https"
  } else {
    return "http"
  }
}

export const SITE_ID = SIRCLO_SITE_ID
export const BASE_URL = `${getProtocol()}://${SIRCLO_SITE_HOST}/api/`
// Replace Access token with Window variables when backend is ready
export const ACCESS_TOKEN = "shop_access"
export const LOGISTIX_BASE_URL = `${getProtocol()}://logistix.sirclo.com.dmmy.me/`
export var GRAPHQL_BASE_URL

if (process.env.NODE_ENV == 'production') {
  GRAPHQL_BASE_URL = `${getProtocol()}://${SIRCLO_SITE_HOST}/graphql`
} else {
  GRAPHQL_BASE_URL = "http://127.0.0.1:8088/graphql"
}

const baseConfig = {
  credentials: "same-origin",
  headers: {
    "Content-Type": "application/json",
    'X-SIRCLO-ACCESS-TOKEN': ACCESS_TOKEN,
    'X-SIRCLO-PLATFORM': 'mobile'
  },
  mode: 'cors',
  cache: 'default'
}
  
const send = (method: any, payload: any) => (
  async function(uri: any, config: any) {
    // Create an array of source config objects to be merged.
    let sources = [config];
    if (method === "POST") {
      sources.push({ body: JSON.stringify(payload) });
    }
    config = Object.assign({
      method: method,
      ...baseConfig,
    }, ...sources);

    return await fetch(BASE_URL + uri, config);
  }
)
  
const get = (uri: any, config = {}) => (
  send("GET", null)(uri, config)
)

const post = (uri: any, data: any, config = {}) => (
  send("POST", data)(uri, config)
)

export {get, post}