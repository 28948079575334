import * as React from "react"
import * as ReactDOM from "react-dom"
import { createStore, applyMiddleware, combineReducers } from "redux"
import { persistReducer, persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"
import storage from "redux-persist/es/storage"
import expireReducer from "redux-persist-expire"
import { Router, Route, Switch } from "react-router"
import { Provider } from "react-redux"
import { createBrowserHistory } from "history"
import { IntlProvider, addLocaleData } from "react-intl"
import ReduxThunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction"

import { cart, auth, uniqueCode } from "./reducers"

import { initializeAppLocaleData } from "./utils/localeData"
import messages_id from "./translations/id.json"
import messages_en from "./translations/en.json"

import Layout from "./components/Layout"
import Loading from "./components/Loading"
import { CheckoutRoute } from "./features/checkout/CheckoutRoute"
import ProductContainer from "./features/products/ProductContainer"
import Contact from "./pages/Contact"
import Cart from "./pages/Cart"
import Home from "./pages/Home"
import ProductDetails from "./features/products/ProductDetails"
import PaymentConfirmation from './pages/PaymentConfirmation'
import Error404 from "./pages/Error404/Error404"
import { AccountRoute } from './features/account/AccountRoute'
import StaticTextPage from "./features/static/StaticTextPage"
import ThankYou from "./pages/ThankYou/ThankYou"

initializeAppLocaleData()

const language = navigator.language.split(/[-_]/)[0]
const messages = {
  'id': messages_id,
  'en': messages_en
}

const persistConfig = {
  key: "root",
  storage: storage,
  transforms: [
    expireReducer("cart", {
      expireSeconds: 259200,
      persistedAtKey: '__persisted_at',
      autoExpire: true,
      expiredState: { 
        itemList: [] 
      }
    }),
    expireReducer("auth", {
      expireSeconds: 864000,
      persistedAtKey: '__persisted_at',
      autoExpire: true,
      expiredState: {
        email: '',
        sessionId: ''
      }
    })
  ]
}

const history = createBrowserHistory();

export const store = createStore(
  persistReducer(persistConfig, combineReducers({ cart, auth, uniqueCode })),
  composeWithDevTools(applyMiddleware(ReduxThunk))
)
const persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale={language} messages={messages[language]}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <Router history={history}>
          <Layout>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/products" component={ProductContainer} />
              <Route exact path="/products/all" component={ProductContainer} />
              <Route path="/products/search/:filterTitle" component={ProductContainer} />
              <Route exact path="/products/search/" component={Home} />
              <Route exact path="/products/category/:categoryId" component={ProductContainer} />
              <Route exact path="/products/:productId" component={ProductDetails} />
              <Route exact path="/cart" component={Cart} />
              <Route path="/payment_notif" component={PaymentConfirmation} />
              <Route path="/thank_you" component={ThankYou} />
              <Route path="/account" component={AccountRoute} />
              <Route path="/static" component={StaticTextPage} />
              <Route path="/checkout" component={CheckoutRoute} />
              <Route path="/contact" component={Contact} />
              <Route component={Error404} />
            </Switch>
          </Layout>
        </Router>
      </PersistGate>
    </IntlProvider>
  </Provider>, 
  document.getElementById('root')
)
