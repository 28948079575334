import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux"
import { Helmet } from 'react-helmet';
import ReactImageMagnify from "react-image-magnify"
import Slider from "react-slick"
import { get } from "../../services/api"
import { graphQLClient } from "../../services/graphQL"
import { isEmpty } from "../../utils/object"
import { addToCart, generateUniqueCode } from "../../actions"
import { Currency } from "../../utils/formatMoney"
import { getUniqueCode } from "../../utils/getUniqueCode";
import ProductCard from "./components/ProductCard"
import Loading from "../../components/Loading"
import "./styles/ProductCarousel.css"

class ProductDetails extends Component<any, any> {

  constructor(props: any) {
    super(props)

    this.state = {
      data: null,
      qty: 1,
      variant1: null,
      variant2: null,
      relatedProductsId: [] as any,
      relatedProducts: [] as any
    }

    this.addToCart = this.addToCart.bind(this)
    this.changeQty = this.changeQty.bind(this)
    this.handleVarChange = this.handleVarChange.bind(this)
  }

  componentDidMount() {
    const { match, productId } = this.props;
    let productID = match.params.productId ? match.params.productId : productId;
    get(`products/${productID}`)
      .then(response => response.json())
      .then(data => this.setState({ data }))

    const query = `
    {
      relatedProductIdById(input: ${productID}) 
    }
    `

    // Add check if relatedProduct does not exist
    graphQLClient.request(query)
    .then(data => this.setState({ relatedProductsId: data["relatedProductIdById"] }))
    .then(data => {
      this.state.relatedProductsId.map((product, index) => {
        get(`products/${product}`)
          .then(response => response.json())
          .then(data => this.setState(prevState => ({ relatedProducts: [...prevState.relatedProducts, data] })))
      })
    })
    .catch(err => console.warn("Terjadi kesalahan memuat Related Products ➜ " + err))
  }

  changeQty(event: any) {
    this.setState({ qty: event.target.value })
  }

  handleVarChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  addToCart = () => {
    const { product } = this.state.data

    this.props.addToCart(product.id, product.title, this.getProductCode(), product.images[0], this.state.qty, this.getProductPrice(), this.state.variant2, this.state.variant1, product.fid)
    if (getUniqueCode()) {
      // Unique code already exist, do nothing
    } else {
      const uniqueCode = Math.floor(Math.random() * (999 - 1 + 1) + 1)
      this.props.generateUniqueCode(uniqueCode)
    }
    this.props.history.push(`/cart`)
  }

  getProductCode() {
    const { data } = this.state

    if (data) {
      if (isEmpty(this.state.data.product.detailed_variants)) {
        return data.product.friendly_id
      } 
      else {
        try {
          return data.product.detailed_variants[`${this.state.variant1 || ""}|${this.state.variant2 || ""}`].friendly_id
        }
        catch(err) {
          return "Product code not available"
        } 
      }
    }
  }

  getProductPrice() {
    const { data } = this.state

    if (data) {
      if (isEmpty(this.state.data.product.detailed_variants)) {
        return data.product.price_raw
      } 
      else {
        try {
          return data.product.detailed_variants[`${this.state.variant1 || ""}|${this.state.variant2 || ""}`].price
        }
        catch(err) {
          return "Variant not available"
        } 
      }
    }
  }

  isProductInStock() {
    const { data } = this.state

    if (data) {
      if (isEmpty(this.state.data.product.detailed_variants)) {
        return data.product.stock > 0
      }
      else {
        try {
          return data.product.detailed_variants[`${this.state.variant1 || ""}|${this.state.variant2 || ""}`].stock > 0
        }
        catch(err) {
          return false
        }
      }
    }
  }

  render() {

    const { data, relatedProducts, relatedProductsId } = this.state
    let variants: string[] = []
    let secondVariants: string[] = []

    const settings = {
      customPaging: function(i) {
        if (data) {
          return (
            <a>
              <img src={data.product.images[i]} />
            </a>
          )
        }
      },
      dots: true,
      dotsClass: "slick-thumb",
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return ( 
      <div className="row">
        <div className="col-lg-12">
          {data ? 
            <>
              <Helmet>
                <title>{`${data.product.title} - Product`}</title>
                <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
                <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
              </Helmet>

              <p style={{ fontFamily: "Roboto Slab", fontWeight: 400, fontSize: 10, color: "#777", textTransform: "uppercase", marginTop: 20, marginBottom: 20 }}>
                <Link to="/">HOME</Link> / <Link to="#">{data.product.title}</Link>
                {/* Uncomment when API to get Product by category slug is ready */}
                {/* <Link to="/">HOME</Link> / <Link to={`/products/category/${data.product.category_ids[0]}`}>CATEGORY #{data.product.category_ids[0]}</Link> / <Link to="#">{data.product.title}</Link> */}
              </p>

              <div className="row mb-5">
                <div className="col-lg-7">
                  <Slider {...settings}>
                    {
                      data.product.images.map((image, index) =>
                        <div>
                          <ReactImageMagnify {...{
                            smallImage: {
                              alt: data.product.title,
                              isFluidWidth: true,
                              src: `${image.replace(/\.[^/.]+$/, "")}_zoom.${image.substring(image.lastIndexOf(".")+1)}`
                            },
                            largeImage: {
                              src: `${image.replace(/\.[^/.]+$/, "")}_zoom.${image.substring(image.lastIndexOf(".")+1)}`,
                              width: 1200,
                              height: 1600,
                            },
                            lensStyle: { backgroundColor: 'rgba(0,0,0,0.6)' },
                            enlargedImagePosition: 'over'
                          }} />
                        </div>
                      )
                    }
                  </Slider>
                </div>

                <div className="col-lg-5">
                  <h1 className="pb-3" style={{ fontFamily: "Montserrat", fontSize: 32 }}>{data.product.title}</h1>

                  <div className="input-group" style={{ display: "inline-flex", fontFamily: "Roboto Slab", fontSize: 13, border: "1px solid #d8d8d8", borderRadius: 5, height: 45, width: 117, color: "#777", marginRight: 10 }}>
                    <label htmlFor="quantity" style={{ paddingTop: 12, paddingLeft: 10 }}>Qty</label>
                    <input onChange={this.changeQty} type="text" value={this.state.qty} className="text-right" style={{ paddingLeft: 10, border: "none", outline: "none", width: 70 }} />
                  </div>

                  {
                    isEmpty(data.product.detailed_variants) ? (
                      null
                    ) : (
                      Object.keys(data.product.detailed_variants).map((variant) => {
                        if (variant.split("|")[0] !== "") {
                          variants.push(variant.split("|")[0])
                        }
                        if (variant.split("|")[1] !== "") {
                          secondVariants.push(variant.split("|")[1])
                        }
                      })
                    )
                  }

                  {
                    variants.length ? (
                      <div className="input-group" style={{ display: "inline-flex", fontFamily: "Roboto Slab", fontSize: 13, border: "1px solid #d8d8d8", borderRadius: 5, height: 45, minWidth: 117, maxWidth: 130, color: "#777", marginRight: 10 }}>
                        <label htmlFor="quantity" style={{ paddingTop: 12, paddingLeft: 10 }}>Size</label>
                        <select name="variant1" onLoad={this.handleVarChange} onChange={this.handleVarChange} style={{ border: "none", background: "none", paddingLeft: 37, paddingBottom: 2, outline: "none" }}>
                          <option> --- </option>
                          {
                            Array.from(new Set(variants)).map((variant) =>
                              <option key={`var1-${variant}`} value={variant}>{variant}</option>
                            )
                          }
                        </select>
                      </div>
                    ) : (
                      null
                    )
                  }
                  
                  {
                    secondVariants.length ? (
                      <div className="input-group" style={{ display: "inline-flex", fontFamily: "Roboto Slab", fontSize: 13, border: "1px solid #d8d8d8", borderRadius: 5, height: 45, minWidth: 117, maxWidth: 130, color: "#777", marginRight: 10 }}>
                        <label htmlFor="quantity" style={{ paddingTop: 12, paddingLeft: 10 }}>Color</label>
                        <select name="variant2" onLoad={this.handleVarChange} onChange={this.handleVarChange}  style={{ border: "none", background: "none", paddingLeft: 9, paddingBottom: 2, outline: "none" }}>
                          <option> --- </option>
                          {
                            Array.from(new Set(secondVariants)).map((variant) =>
                              <option key={`var2-${variant}`} value={variant}>{variant}</option>
                            )
                          }
                        </select>
                      </div>
                    ) : (
                      null
                    )
                  }

                  <p style={{ color: "#f33", fontSize: 21, fontFamily: "Montserrat", margin: "15px 0" }}>
                    {
                      isEmpty(this.state.data.product.detailed_variants) ? (
                        <>
                          { 
                            this.state.data.product.usual_price_raw ? 
                              <>
                                <span style={{ fontFamily: 'Montserrat', color: "#999", fontSize: 14, textDecoration: "line-through"  }}>
                                  <Currency value={data.product.usual_price_raw} />
                                </span>
                                <br />
                              </>
                            :
                              null
                          }
                          <Currency value={data.product.price_raw} />
                        </>
                      ) : (
                        <>
                          {
                            isNaN(this.getProductPrice()) ? (
                              this.getProductPrice()
                            ) : (
                              <>
                                {
                                  this.state.data.product.usual_price_raw ? (
                                    <>
                                      <span style={{ fontFamily: 'Montserrat', color: "#999", fontSize: 14, textDecoration: "line-through"  }}>
                                        <Currency value={data.product.usual_price_raw} />
                                      </span>
                                      <br />
                                    </>
                                  ) : null
                                }
                                <Currency value={this.getProductPrice()}/>
                              </>
                            )
                          }
                        </>
                      )
                    }
                  </p>
                  
                  {
                    this.isProductInStock() ? (
                      <button className="btn btn-dark btn-lg" style={{ display: "flex", justifyContent: "center", background: "#222", fontFamily: "Montserrat", textTransform: "uppercase", fontSize: 13, padding: 14, width: 170, border: "none" }} onClick={this.addToCart} disabled={this.state.qty < 1}>Add to Bag</button>
                    ) : (
                      <button className="btn btn-dark btn-lg" style={{ display: "flex", justifyContent: "center", background: "#222", fontFamily: "Montserrat", textTransform: "uppercase", fontSize: 13, padding: 14, width: 170, border: "none" }} disabled={true}>Unavailable</button>
                    )
                  }

                  <p style={{ fontFamily: "Roboto Slab", fontSize: 13, color: "#777", marginTop: 40 }}><span dangerouslySetInnerHTML={{ __html: data.product.specification}} /></p>

                </div>
              </div>

              {
                  relatedProductsId.length > 1 ? (
                    <div style={{ marginTop: 20, marginBottom: 15 }}>
                      <h2 style={{ fontFamily: "Lato", fontWeight: 900, margin: "0 0 15px", fontSize: 20, textTransform: "uppercase" }}>Related Products</h2>
                        <div className="row justify-content-center">
                          {
                            relatedProducts.map((product, index) => 
                              <ProductCard 
                                key={index} 
                                id={product.product.id} 
                                title={product.product.title} 
                                usualPrice={product.product.usual_price_raw}
                                price={product.product.price_raw}
                                image={product.product.images[0]}
                              />
                            )
                          }
                        </div>
                    </div>
                  ) : null
                }
            </>
          : 
            <Loading />
          }
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  addToCart, 
  generateUniqueCode

}

export default withRouter<any>(connect(null, mapDispatchToProps)(ProductDetails));