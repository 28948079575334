import React from "react";

import "./Notification.css";

export function ErrorNotif(props: { message: string }) {
  return(
    <div className="error-notification">
      {props.message}
    </div>
  )
}
