import React, { Component } from "react"
import { Helmet } from "react-helmet"
import Slider from "react-slick"
import "./Carousel.css"

class Carousel extends Component {
  render() {

    const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slideToShow: 1,
      className: 'slides',
      adaptiveHeight: true
    }

    return (
      <>
        <Helmet>
          <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
          <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        </Helmet>
        <Slider {...settings}>
          {this.props.children}
        </Slider>
      </>
    )
  }

}

export default Carousel