export const ADD_TO_CART = "ADD_TO_CART"
export const REMOVE_ITEM = "REMOVE_ITEM"
export const CHANGE_QTY = "CHANGE_QTY"
export const ADD_QTY = "ADD_QTY"
export const CLEAR_CART = "CLEAR_CART"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGOUT = "LOGOUT"
export const GENERATE_UNIQUE_CODE = "GENERATE_UNIQUE_CODE"
export const PURGE_UNIQUE_CODE = "PURGE_UNIQUE_CODE"

// TO DO: Remove getState from Action Creators
export function getCartState(state): {id: any, title: string, productCode: string, image: string, qty: number, price: number, color: string, size: string}[] {
  return state.cart.itemList;
}

export function login(email: string, sessionId: string) {
  return ({
    type: LOGIN_SUCCESS,
    payload: { email, sessionId },
  })
}

export function logout() {
  return ({
    type: LOGOUT
  })
}

export function addToCart(id: any, title: string, productCode: string, image: string, qty: number, price: number, color, size, fid: string) {
  return async (dispatch, getState) => {
    const cartItems = getCartState(getState());
    const index = cartItems.findIndex(item => productCode === item["productCode"])
    if (index > -1) {
      dispatch({
        type: ADD_QTY,
        payload: { productCode, qty, index },
      })
    } else {
      dispatch({
        type: ADD_TO_CART,
        payload: { id, title, productCode, image, qty, price, color, size, fid }
      })
    }
  }
}

export function removeItem(productCode: string) {
  return {
    type: REMOVE_ITEM,
    payload: { productCode }
  }
}

export function changeQuantity(productCode, qty) {
  return async (dispatch, getState) => {
    const cartItems = getCartState(getState());
    const index = cartItems.findIndex(item => productCode === item["productCode"])
    dispatch({
      type: CHANGE_QTY,
      payload: { qty, productCode, index }
    })
  }
}

export function clearCart() {
  return ({
    type: CLEAR_CART
  })
}

export function generateUniqueCode(uniqueCode) {
  return ({
    type: GENERATE_UNIQUE_CODE,
    payload: { uniqueCode }
  })
}

export function purgeUniqueCode() {
  return ({
    type: PURGE_UNIQUE_CODE
  })
}