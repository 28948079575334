import React, { Component } from "react";
import { Helmet } from "react-helmet"
import { toast } from "react-toastify"
import moment from "moment"
import queryString from "query-string"
import { graphQLClient } from "../services/graphQL"
import { Currency } from "../utils/formatMoney"
import CustomInputLarger from "../components/CustomInputLarger";
import StaticContent from "../utils/staticContent/staticContent"
import { ACCESS_TOKEN, BASE_URL } from "../services/api";

class PaymentConfirmation extends Component<any, any> {
  state = {
    orderId: "",
    transactionDate: "",
    senderName: "",
    amountTransferred: "",
    bankTo: "",
    email: "",
    totalAmount: 0,
    accountNumbers: [] as any
  }

  constructor(props) {
    super(props)

    this.handleBankToChange = this.handleBankToChange.bind(this)
  }
  
  componentDidMount() {
    window.scrollTo(0, 0)

    const query = `
    {
      accountNumbers {
        title number
      }
    }
    `

    graphQLClient.request(query)
      .then(data => this.setState({ accountNumbers: data["accountNumbers"] }))
      .catch(err => console.warn("Gagal memuat Bank Account: " + err))
    
    const values = queryString.parse(this.props.location.search)
    // console.log(values)
    if (values.order_id) {
      this.setState({ 
        orderId: values.order_id,
        totalAmount: values.total_amount || null,
        email: values.order_email
      })
    }
  }

  handleBankToChange(event) {
    this.setState({ bankTo: event.target.value })
  }

  async submitConfirmation() {
    const { orderId, transactionDate, senderName, amountTransferred, bankTo, email} = this.state;
    let formattedTransactionDate = moment(transactionDate).format('YYYY-MM-DD').toString()

    let body = new FormData()
    body.append('order_id', orderId)
    body.append('email', email)
    body.append('bank_to', bankTo)
    body.append('transaction_date', formattedTransactionDate)
    body.append('transaction_reference', senderName)
    body.append('amount_transfered', amountTransferred)

    const args = {
      method: 'POST',
      headers: {
        'X-SIRCLO-ACCESS-TOKEN': ACCESS_TOKEN,
      },
      body
    }
  
    let result = await (await fetch(BASE_URL + "payment_notif", args)).json();
    if (result.status === 200) {
      toast.success(result.message)
    } else {
      toast.error(result.message)
    }
  }
  render() {
    const { 
      orderId,
      transactionDate,
      senderName,
      amountTransferred,
      bankTo,
      email,
      totalAmount,
      accountNumbers
    } = this.state;

    return (
      <div>
        <Helmet> 
          <title>Payment Confirmation</title>
        </Helmet>

        <h1 style={{ fontSize: 32, textTransform: "uppercase", marginTop: 60, marginBottom: 65, textAlign: "center", fontFamily: "Lato", fontWeight: 900 }}>PAYMENT CONFIRMATION</h1>
        <div style={{ fontFamily: "Roboto Slab", fontWeight: 400, fontSize: 13, color: "#777"  }}>
          {
            totalAmount ? (
              <>Your Order Total: <Currency value={totalAmount} /></>
            ) : (
              null
            )
          }
          <br/>
          <StaticContent title="Bank Account Info" />
          <ul>
            {
              accountNumbers ? (
                accountNumbers.map((acc, index) => <li key={index}>{acc.title} - {acc.number}</li>)
              ) : null
            }
          </ul>
          <br/>

          {/* <form> */}
          <CustomInputLarger 
            required={true} 
            label="Order ID" 
            type={"text"} 
            value={orderId} 
            onChange={e => this.setState({ orderId: e.target.value })}
          />
          <CustomInputLarger 
            required={true} 
            label="Transaction Date" 
            type={"date"} 
            value={transactionDate} 
            onChange={e => this.setState({ transactionDate: e.target.value })}
          />
          <CustomInputLarger 
            required={true} 
            label="Sender Name" 
            type={"text"} 
            value={senderName} 
            onChange={e => this.setState({ senderName: e.target.value })}
          />
          <CustomInputLarger 
            required={true} 
            label="Amount Transferred" 
            type={"number"} 
            value={amountTransferred} 
            onChange={e => this.setState({ amountTransferred: e.target.value })}
          />

          <div className="form-group row">
            <label className="col-sm-2 col-form-label" style={{ paddingTop: 15, fontWeight: 700, color: "#777", fontFamily: "Roboto Slab", fontSize: 12 }}>Payment to <span style={{ color: "#ffa500", marginLeft: 3 }}>*</span></label>
            <div className="col-sm-8">
              <div style={{ paddingTop: 5 }}>
                <select value={this.state.bankTo} onChange={this.handleBankToChange} style={{ padding: "14px 10px", background: "none", width: "100%", border: "1px solid rgb(187, 187, 187)", borderRadius: 5 }}>
                  <option>-- Select Bank Account --</option>
                    {
                    accountNumbers ? (
                      accountNumbers.map((acc, index) => <option key={index} value={acc.title}>{acc.title}</option>)
                      ) : null
                    }
                </select>
              </div>
            </div>
          </div>

          <CustomInputLarger 
            required={true} 
            label="Email" 
            type={"email"} 
            value={email} 
            onChange={e => this.setState({ email: e.target.value })}
          />
          
          {/* <div className="form-group row">
            <label className="col-sm-2 col-form-label" style={{ paddingTop: 15, fontWeight: 700 }}>Receipt File <span style={{ color: "#ff0000" }}>(optional, max. 5MB)</span></label>
            <div className="col-sm-3">
              <input type="file" className="form-control-plaintext" />
            </div>
          </div> */}

          <small id="emailHelp" className="form-text text-muted" style={{ marginBottom: 20 }}>Fields marked with <span style={{ color: "#ffa500", marginLeft: 3 }}>*</span> are required.</small>
          <button className="btn btn-dark btn-lg" style={{ background: "#222", fontFamily: "Montserrat", textTransform: "uppercase", fontSize: 13, padding: 14, width: 170, border: "none", marginBottom: 35 }} onClick={() => this.submitConfirmation()}>Confirm Payment</button>

          {/* </form> */}
        </div>
      </div>
    );
  }
}

export default PaymentConfirmation;