import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { SIRCLO_FAVICON_URL } from "../configs"
import "../styles/index.css"

class Head extends Component {
  render() {
    return (
      <>
        <Helmet>
          <link rel="shortcut icon" href={SIRCLO_FAVICON_URL} />
        </Helmet>
      </>
    )
  }
}

export default Head