import React, { Component } from "react";

function renderAccountInfo() {
  // Ambil data dari redux terus di masukin ke dalam value input
  let accountInfoFields = ["Name", "Phone", "Address", "Postal Code", "City", "State", "Country", "State"];
  function typeOfInput(fieldName: string) {
    switch(fieldName) {
      case "Phone":
      case "Postal Code":
        return "number";
      case "Birthdate":
        return "date";
      default:
        return "text";
    }
  }

  return accountInfoFields.map((field, index) => {
    return (
    <div key={index} style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ width: 108 }} className="account-info__field-name">
        {field}
      </div>
      <div>
        {/* dropdown negara belum dibuat */}
        <input type={typeOfInput(field)} />
      </div>
    </div>
  )});
}

class AccountEdit extends Component {
  render() {
    return(
      <div>
        <h1>EDIT ACCOUNT INFO</h1>
        {renderAccountInfo()}
      </div>
    );
  }
}

export default AccountEdit;