import { addLocaleData } from "react-intl";

import enLocaleData from "react-intl/locale-data/en";
import idLocaleData from "react-intl/locale-data/id";

export const localeData = {
  enLocaleData,
  idLocaleData
};

export function initializeAppLocaleData() {(
  Object.keys(localeData).forEach(specificLocale => addLocaleData(localeData[specificLocale]))
)};
