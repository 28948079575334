import React, { Component } from "react";
import CustomInput from "../../components/CustomInput";
import { BASE_URL, ACCESS_TOKEN } from "../../services/api";
import { SuccessNotif } from "../../components/Notification/SuccessNotif";
import { ErrorNotif } from "../../components/Notification/ErrorNotif";

type AccountResetPasswordType = {
  email: string;
  respMessage: string;
  showSuccess: boolean;
  showError: boolean;
}

export class AccountResetPassword extends Component<{}, AccountResetPasswordType> {
  state = {
    email: "",
    respMessage: "Testing",
    showSuccess: false,
    showError: false,
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  async onSubmitResetPass() {
    // fetch API

    let body = new FormData();
    body.append("email", this.state.email);

    try {
      let result = await (await fetch(BASE_URL + "reset_password", {
        method: "POST",
        body
      })).json()

      if (result.status === 200) {
        this.setState({ showSuccess: true, email: "", respMessage: result.message });
      } else {
        throw new Error();
      }
    } catch(err) {
      this.setState({ showError: true, respMessage: "Terjadi Kesalahan" });
    }
    
  }

  render() {
    const { showSuccess, showError } = this.state;
    return (
      <>
        {showSuccess ? <SuccessNotif message={this.state.respMessage}/> : null}
        {showError ? <ErrorNotif message={this.state.respMessage}/> : null}

        <div>
          <h2 style={{ textAlign: "center", marginBottom: 30 }}>RESET YOUR PASSWORD</h2>
          {/* <CustomInput onChange={event => this.onEmailChange(event)} type="text" value={this.state.email} label="Email"/> */}
          <button style={{ background: "black", color: "white", borderRadius: 3, width: 260, height: 55 }} onClick={() => this.onSubmitResetPass()}>LOGIN</button>
        </div>
      </>
    )
  }
}
