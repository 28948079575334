import React, { Component } from "react";
import { withRouter } from "react-router";
import { get } from "../../services/api";
import "./staticContent.css";

class StaticContent extends Component<any, any> {

  constructor(props: any) {
    super(props)

    this.state = {
      data: []
    }
  }

  componentDidMount() {
    try {
      get("static_contents")
      .then(response => response.json())
      .then(data => this.setState({ data }))
    }
    catch (err) {
      console.warn("Terjadi kesalahan memuat Static Contents ➜ " + err)
    }
  }

  replaceArray(string, find, replace) {
    var replaceString = string;
    var regex; 
    for (var i = 0; i < find.length; i++) {
      regex = new RegExp(find[i], "g");
      replaceString = replaceString.replace(regex, replace[i]);
    }
    return replaceString;
  };

  isURL(str) {
    let urlRegex = '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
    let url = new RegExp(urlRegex, 'i');
    return str.length < 2083 && url.test(str);
  }

  navigate (event) {
    event.preventDefault()
    if (event.target.tagName === 'A') {
      let href = event.target.getAttribute('href')
      if(this.isURL(href) === false) this.props.history.push(href)
      else window.location = href;
    }
  }

  getStaticContent(title) {
    const { static_contents } = this.state.data

    try {
      if (static_contents) {
        let scIndex = static_contents.findIndex(sc => sc.title === title)
        let content = static_contents[scIndex].content;

        return <div dangerouslySetInnerHTML={{__html: content}} onClick={event => this.navigate(event)}/>
      }
    }
    catch {
      return ""
    }
  }

  render() {
    const { title, className } = this.props
    const { data } = this.state

    return (
      <>
        {
          data ? (
            <span className={className || ""}>{this.getStaticContent(title)}</span> 
          ) : (
            null
          )
        }
      </>
    )
  }
}

export default withRouter<any>(StaticContent);