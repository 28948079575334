import React, { Component } from "react";
import { Helmet } from "react-helmet"
import { withRouter, Redirect } from "react-router";
import { NavLink } from "react-router-dom";

// type AccChangePassPropTypes = {
//   history: any
// }

type AccChangePassStateTypes = {
  oldPassword: string;
  newPassword: string;
  confNewPassword: string;
}

class AccountChangePassword extends React.Component<any, AccChangePassStateTypes> {
  state = {
    oldPassword: "",
    newPassword: "",
    confNewPassword: ""
  }

  onChangePasswordForm(value, type: string) {
    if (type === "old") {
      this.setState({ oldPassword: value });
    } else if (type === "new") {
      this.setState({ newPassword: value });
    } else if (type === "confirm") {
      this.setState({ confNewPassword: value });
    }
  }

  submitPasswordChange() {
    const { oldPassword, newPassword, confNewPassword } = this.state;
    if (oldPassword === "" || newPassword === "" || confNewPassword === "") {
      alert("Silahkan lengkapi form");
    } else if (newPassword !== confNewPassword) {
      alert("Konfirmasi password Anda tidak sama");
    } else {
      
    }
  }

  render() {
    const { oldPassword, newPassword, confNewPassword } = this.state;
    return(
      <>
        <Helmet>
          <title>Change Password</title>
        </Helmet>
        <div style={{ fontFamily: "Lato", marginBottom: 40 }}>
          <h1 style={{ fontWeight: 900, fontSize: 32, color: "#333" }} className="mt-5 mb-5">CHANGE PASSWORD</h1>

          <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
            <div style={{ width: 400, fontFamily: "Roboto Slab", fontSize: 13, paddingTop: 10 }}>Old Password<sup style={{ color: "red" }}>*</sup></div>
            <input className="form-control-plaintext" style={{ border: "1px solid #bbb", borderRadius: 5, padding: "8px 10px" }} value={oldPassword} onChange={(event) => this.onChangePasswordForm(event.target.value, "old")} type="password"/>
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
            <div style={{ width: 400, fontFamily: "Roboto Slab", fontSize: 13, paddingTop: 10 }}>New Password<sup style={{ color: "red" }}>*</sup></div>
            <input className="form-control-plaintext" style={{ border: "1px solid #bbb", borderRadius: 5, padding: "8px 10px" }} value={newPassword} onChange={(event) => this.onChangePasswordForm(event.target.value, "new")} type="password"/>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: 400, fontFamily: "Roboto Slab", fontSize: 13, paddingTop: 10 }}>Confirm Old Password<sup style={{ color: "red" }}>*</sup></div>
            <input className="form-control-plaintext" style={{ border: "1px solid #bbb", borderRadius: 5, padding: "8px 10px" }} value={confNewPassword} onChange={(event) => this.onChangePasswordForm(event.target.value, "confirm")} type="password"/>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <button style={{ border: "none", marginTop: 40, background: "black", color: "white", borderRadius: 3, fontSize: 13, padding: "7px 18px", fontWeight: 700, marginRight: 10 }} onClick={this.submitPasswordChange}>CHANGE PASSWORD</button>
            <button style={{ border: "none", marginTop: 40, background: "black", color: "white", borderRadius: 3, fontSize: 13, padding: "7px 18px", fontWeight: 700 }} onClick={() => this.props.history.push("/account")}>CANCEL</button>
          </div>
        </div>
      </>
    )
  }
}

export default AccountChangePassword;