import React, { Component } from "react";
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"

class Error404 extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Page Not Found</title>
        </Helmet>
        <div className="text-center mt-5">
          <img src={require('./images/404.png')} /> 
          <h1 style={{ fontFamily: "Lato", fontWeight: 900, fontSize: 32, textTransform: "uppercase", margin: "60px 0 50px" }}>
            WE’RE SORRY, BUT THE PAGE YOU’RE LOOKING FOR CAN’T FOUND
          </h1>
          <p style={{ fontFamily: "Roboto Slab", fontSize: 13, color: "#777" }}>
            Maybe it moved. Or maybe it never existed. Sorry.<br /><br />
            <Link to="/">Back to Home</Link>
          </p>
        </div>
      </>
    )
  }
}

export default Error404;