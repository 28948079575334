import React, { Component } from "react";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet"

type AccountHistoryPropType = {
  history: any;
}

type AccountHistoryStateType = {
  orderHistory: any;
}

class AccountHistory extends Component<AccountHistoryPropType, AccountHistoryStateType> {

  state = {
    orderHistory: [],
  }

  renderHistory() {
    const { orderHistory } = this.state;
    if (orderHistory.length < 1) {
      return "There are no orders in your history";
    } else {
      return (
        orderHistory.map((order, index) => {
          <div key={index}>
            {order}
          </div>
        })
      )
    }
  }

  render() {
    return(
      <>
        <Helmet>
          <title>Orders</title>
        </Helmet>
        <div style={{ fontFamily: "Lato" }}>
          <h1 style={{ fontWeight: 900, fontSize: 32, color: "#333" }} className="mt-5 mb-5">ORDER HISTORY</h1>

          <div style={{ fontFamily: "Roboto Slab", fontSize: 13, color: "#777" }}>{this.renderHistory()}</div>
          <button style={{ border: "none", marginTop: 40, background: "black", color: "white", borderRadius: 3, fontSize: 13, padding: "7px 18px", fontWeight: 700 }} onClick={() => this.props.history.push("/account")}>BACK TO MY ACCOUNT</button>
        </div>
      </>
    )
  }
}

export default withRouter<any>(AccountHistory);