import React from "react";
import { Switch, Route, Redirect } from "react-router";
import CheckoutLogin from "./CheckoutLogin";
import CheckoutForm from "./CheckoutForm";
import CheckoutFormMultiAddress from "./CheckoutFormMultiAddress";

export function CheckoutRoute() {
  // cek apakah sudah login dari redux
  // let login = false;
  // if (!login) {
  //   <Redirect to="/account/login"/>    
  // } 

  return (
    <Switch>
      <Route exact path="/checkout" component={CheckoutLogin}/>
      <Route path="/checkout/place_order" component={CheckoutForm}/>
      <Route path="/checkout/place_orders" component={CheckoutFormMultiAddress}/>
    </Switch>
  );
}
