import React, { Component } from "react"
import { Link } from "react-router-dom";
import { connect } from "react-redux"
import { NavDropdown } from "react-bootstrap"
import { BASE_URL, ACCESS_TOKEN } from "../../services/api";
import { SIRCLO_LOGO_URL }  from "../../configs"
import { graphQLClient } from "../../services/graphQL"
import { logout } from "../../actions";
import { isAuth } from "../../utils/isAuth"
import Head from "../Head"
import CartLink from "./components/CartLink"
import "./Header.css"

class Header extends Component<any, any> {
  state = {
    navigation: [] as any,
    searchQuery: ""
  }

  constructor(props) {
    super(props)

    this.handleSearch = this.handleSearch.bind(this)
  }

  async componentDidMount() {
    
    const query = `
      {
        mainNavigation {
          title linkToPage link category child {
            title linkToPage link category
          }
        }
      }
    `

    graphQLClient.request(query)
      .then(data => this.setState({ navigation: data["mainNavigation"] }))
      .catch(err => console.warn("Terjadi kesalahan memuat Main Navigation ➜ " + err))

  }

  handleSearch(event) {
    event.preventDefault()

    if (this.state.searchQuery) {
      window.location.replace(`/products/search/${this.state.searchQuery.split(' ').join('+')}`)
    } else {
      window.location.replace(`/products/all`)
    }
  }

  async onLogout() {
    let result = await fetch(`${BASE_URL}account/logout`, {
      method: "POST",
      headers: {
        "X-SIRCLO-ACCESS-TOKEN": ACCESS_TOKEN,
      },
      mode: 'cors',
      cache: 'default'
    })
    if (result.ok) {
      this.props.logout()
      window.location.replace(`/account/login`)
    }
  }

  render() {
    const { navigation } = this.state;
    
    function getNavigationLink(linkToPage, category = "", link = "") {
      switch(linkToPage) {
        case "products":
          return `products/category/${category.toLowerCase().split(" ").join("-")}`
        case "photos":
          return "lookbook"
        case "_custom":
          return `//${link.replace("http://", "")}`
        default:
          return linkToPage
      }
    }

    function renderNavigation() {
      return navigation.map((nav, index) => (
          nav.child.length < 1 ? (
            <li key={index} className="nav-item" style={{ textTransform: "uppercase" }}>
              <a href={`/${getNavigationLink(nav.linkToPage, nav.category, nav.link)}`}>
                <span className="nav-link">{nav.title!}</span>
              </a>
            </li>
          ) : (
            <div key={index} className="dropdown nav-item" style={{ textTransform: "uppercase", background: "#fff" }}>
              <a id="basic-nav-dropdown" href={`/${getNavigationLink(nav.linkToPage, nav.category, nav.link)}`} className="dropdown-toggle nav-link" role="button">
                {nav.title}
              </a>
              <div className="dropdown-menu" x-placement="bottom-start" style={{ background: "#fff", marginTop: -10, marginLeft: 30, transform: "translate3d(0px, 32px, 0px)" }}>
                {nav.child.map((nav, index) => (
                  <NavDropdown.Item key={index} href={`/${getNavigationLink(nav.linkToPage, nav.category, nav.link)}`} style={{ background: "#fff", color: "#777" }}>
                    {nav.title}
                  </NavDropdown.Item>
                ))}
              </div>
            </div>
          )          
        )
      )
    }

    function renderNavigationMobile() {
      return navigation.map((nav, index) => (
          nav.child.length < 1 ? (
            <option key={index} value={`/${getNavigationLink(nav.linkToPage, nav.category, nav.link)}`} style={{ textTransform: "uppercase" }}>
              {nav.title!}
            </option>
          ) : (
            <>
              <option key={index} value={`/${getNavigationLink(nav.linkToPage, nav.category, nav.link)}`} style={{ textTransform: "uppercase" }}>
                {nav.title!} »
              </option>
              {nav.child.map((nav, index) => (
                <option key={index} value={`/${getNavigationLink(nav.linkToPage, nav.category, nav.link)}`} style={{ textTransform: "uppercase" }}>
                 - {nav.title}
                </option>
              ))}
            </>
          )          
        )
      )
    }

    return (
      <>
        <Head />
        <div className="header-top-navigation">
          <div className="container">
            <nav className="navbar navbar-expand-sm navbar-light" style={{ padding: 0 }}>
              <ul className="navbar-nav mr-auto" style={{ fontSize: 11 }}>
                <li className="nav-item" style={{ display: "none" }}>
                  <a className="nav-link" href="#">ENGLISH</a>
                </li>
                <li className="nav-item" style={{ display: "none" }}>
                  <span className="nav-link">|</span>
                </li>
                <li className="nav-item" style={{ display: "none" }}>
                  <a className="nav-link" href="#">BAHASA</a>
                </li>
              </ul>
              <ul className="navbar-nav navbar-nav--mobile">
                <li className="nav-item" style={{ borderLeft: "1px solid #ededed" }}>
                  <form onSubmit={this.handleSearch}>
                    <input onChange={(e) => this.setState({ searchQuery: e.target.value })} className="mr-sm-2" style={{ border: "none", outline: "none", position: "relative", top: 10, paddingLeft: 10, marginLeft: 45, width: 64, textTransform: "uppercase" }} type="search" placeholder="SEARCH" aria-label="Search" />
                    <input onClick={this.handleSearch} type="submit" className="header-top-search" value="" />
                  </form>
                </li>
                { 
                  isAuth() ? ( 
                    <NavDropdown title="MY ACCOUNT" id="basic-nav-dropdown" style={{ borderLeft: "1px solid #ededed", padding: "6px 15px" }}>
                      <NavDropdown.Item style={{ maxWidth: 120 }}>
                        <Link to="/account">
                          Account Info
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ maxWidth: 120 }}>
                        <Link to="#">
                          <span className="dropdown-item" onClick={() => this.onLogout()}>Logout</span>
                        </Link>
                      </NavDropdown.Item>
                    </NavDropdown>
                  ) : ( 
                    <li className="nav-item" style={{ borderLeft: "1px solid #ededed", borderRight: "1px solid #ededed", padding: "6px 15px" }}>
                      <Link to="/account/login">
                        <span className="nav-link">LOGIN</span>
                      </Link>
                    </li>
                  ) 
                }
                <li className="nav-item" style={{ borderLeft: "1px solid #ededed", borderRight: "1px solid #ededed", padding: "6px 15px" }}>
                  <Link to="/payment_notif">
                    <span className="nav-link">CONFIRM PAYMENT</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="container">
          <div className="header">
            <nav className="navbar navbar-expand-sm navbar-light justify-center--mobile-only" style={{ padding: "20px 0" }}>
              <Link to="/">
                <img src={`//thumbor.sirclocdn.xyz/unsafe/filters:quality(25)/https:${SIRCLO_LOGO_URL}`} className="logo" />
              </Link>
              <ul className="navbar-nav mr-auto desktop-only" style={{ fontSize: 11, color: "rgba(0, 0, 0, 0.7)" }}>
                {renderNavigation()}
              </ul>

              <div className="mobile-only" style={{ width: "100%" }}>
                <select 
                  style={{ width: "100%", border: "5px solid #000", background: "#fff", height: 39, fontSize: 11, padding: 5, textTransform: "uppercase" }}
                  onChange={(e) => window.open(e.target.value, '_self')}>
                  <option>Menu</option>
                  {renderNavigationMobile()}
                </select>
              </div>

              <ul className="navbar-nav" style={{ fontSize: 11 }}>
                <li className="nav-item cartlink-mobile">
                  <Link to="/cart">
                    <CartLink />
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </>
    )
  }
}

export function mapStateToProps(state) {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = {
  logout
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)