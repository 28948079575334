import React, { Component } from "react"
import { Helmet } from "react-helmet"
import Loading from "../../components/Loading";
import ProductCard from "./components/ProductCard"

class ProductList extends Component<any, any> {

  constructor(props: any) {
    super(props)
  }

  state = {
    selectedProduct: null,
    data: null as any,
    isLoading: true,
    searchQuery: ""
  }

  render() {
    const { productList, pageTitle, loading } = this.props
    return (
      <>
        <Helmet>
          <title>{`${pageTitle}`}</title>
        </Helmet>
        
        <h1 style={{ fontSize: 32, textTransform: "uppercase", marginTop: 60, marginBottom: 65, textAlign: "center", fontFamily: "Lato", fontWeight: 900 }}>
          { pageTitle }
        </h1>
        {
          productList ? 
            <div className="row justify-content-center">
              {
                productList.map((product) => 
                  <ProductCard 
                    id={product.id}
                    title={product.title}
                    image={product.images[0]}
                    price={product.price_raw}
                    usualPrice={product.usual_price_raw}
                  />
                )
              }
            </div>
          : 
            <Loading />
        }

        {
          loading ? (
            <Loading />
          ) : null
        }
      </>
    )
  }
}

export default ProductList