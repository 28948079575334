import React, { Component } from "react"
import { Route, Redirect } from "react-router-dom"
import { isAuth } from "../utils/isAuth"

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuth() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/account/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
)

export default PrivateRoute