import React, { Component } from "react";
import { FormattedNumber } from "react-intl";

type CurrencyProps = {
  value: number;
  currency?: "IDR" | "USD" | "GBP" | "EUR";

}
export class Currency extends Component<CurrencyProps, {}> {

  // This class should be able to access global state that contains selected locale
  render() {
    const { value, currency } = this.props;
    return (
      <FormattedNumber 
        style="currency"
        currency={currency ? currency : "IDR"}
        value={value}
      />
    )
  }
}