import { store } from "../index"

const isAuth = () => {
  if (store.getState().auth.sessionId != "") {
    return true
  } else {
    return false
  }
}

export { isAuth }