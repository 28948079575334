import React, { Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { Helmet } from "react-helmet"
import { toast } from "react-toastify"
import { get, ACCESS_TOKEN, BASE_URL } from "../../services/api"
import Loading from "../../components/Loading"

class AccountInfo extends Component<any, any> {

  state = {
    data: null as any
  }

  renderAccountInfo() {
  
    try {
      const { member } = this.state.data
      let accountInfoFields = ["Name", "Phone", "Address", "Postal Code", "City", "State", "Country"];
      let accountInfoDatas = ["first_name", "phone", "address_line1", "postal_code", "city", "state", "country"]
      return accountInfoFields.map((field, index) => (
        <div key={index} style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ fontFamily: "Roboto Slab", fontSize: 13, width: 108, color: "#777", fontWeight: "bold" }} className="account-info__field-name">
            {field}
          </div>
          <div>
            : <span style={{ fontFamily: "Roboto Slab", fontSize: 13, fontWeight: 400, color: "#777" }}>{member[`${accountInfoDatas[index]}`]}</span>
          </div>
        </div>
      ));
    }
    catch {
      return <Loading />
    }
  }

  async componentDidMount() {

    try {
      let result = await fetch(BASE_URL + "account/details", {
        method: "GET",
        headers: {
          "X-SIRCLO-ACCESS-TOKEN": ACCESS_TOKEN,
          "X-SIRCLO-SESSION-ID": this.props.auth.sessionId,
        },
        cache: 'no-cache'
      })
      if (result.ok) {
        result.json().then(data => this.setState({ data }))
      }
    } catch(err) {
      toast.warn("Terjadi Kesalahan memuat Account Details ➜ " + err)
    }
  }

  render() {
    return(
      <>
        <Helmet>
          <title>My Account</title>
        </Helmet>
        <div style={{ fontFamily: "Lato", marginBottom: 40 }}>
          <h1 style={{ fontWeight: 900, fontSize: 32, color: "#333" }} className="mt-5 mb-5">ACCOUNT INFO</h1>
          {/* <p style={{ marginTop: 30, fontSize: 12, textTransform: "uppercase", fontWeight: 900 }}>Account Info <span style={{ fontFamily: "Roboto Slab", fontSize: 13, textTransform: "none", fontWeight: 400 }}>(<Link to="/account/info/edit">edit</Link>)</span></p> */}
          
          {this.renderAccountInfo()}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  }
}

export default connect<any>(mapStateToProps)(AccountInfo);