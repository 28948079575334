import React from "react";
import { Switch, Route, Redirect } from "react-router";

import PrivateRoute from "../../components/PrivateRoute"

import AccountContainer from "./AccountContainer";
import AccountInfo from "./AccountInfo";
import AccountEdit from "./AccountEdit";
import AccountHistory from "./AccountHistory";
import AccountReferral from "./AccountReferral";
import AccountChangePassword from "./AccountChangePassword";
import AccountLogin from "./AccountLogin";
import AccountRegister from "./AccountRegister";
import { AccountResetPassword } from "./AccountResetPassword";

export function AccountRoute() {
  // cek apakah sudah login dari redux
  let login = false;
  if (!login) {
    <Redirect to="/account/login"/>    
  } 

  return (
    <Switch>
      <Route path="/account/login" component={AccountLogin}/>
      <Route path="/account/register" component={AccountRegister}/>
      <Route path="/account/reset" component={AccountResetPassword}/>
      <AccountContainer>
        <Switch>
          <PrivateRoute exact path="/account" component={AccountInfo}/>
          <PrivateRoute path="/account/edit" component={AccountEdit}/>
          <PrivateRoute path="/account/orders" component={AccountHistory}/>
          <PrivateRoute path="/account/referral" component={AccountReferral}/>
          <PrivateRoute path="/account/change_password" component={AccountChangePassword}/>
        </Switch>
      </AccountContainer>
    </Switch>
  );
}
