import React, { Component } from "react";
import { withRouter } from "react-router";

// NOTES: Not finished yet
class StaticTextPage extends Component {
  state = {
    shownPage: "",
    pages: {
      "Privacy Policy": {
        pageContent: ""
      },
      "Terms of Use": {
        pageContent: ""
      }
    },
  }
  changePage(pageName: string) {
    this.setState({ shownPage: pageName });
  }

  renderListOfPage() {
    // fetch API and loop the result
    const { pages } = this.state;
    return Object.keys(pages).map((pageName, index) => {
      <div onClick={() => this.changePage(pages[pageName].name)}> 
        {pages[pageName].name}
      </div>
    })
  }

  renderPageContent() {

  }
  render() {
    return(
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="static__page-list">
          {this.renderListOfPage()}
        </div>
        <div className="static__page-content">

        </div>
      </div>
    );
  }
}

export default withRouter<any>(StaticTextPage);