import React, { Component } from "react";
import { Helmet } from "react-helmet"

class AccountReferral extends Component {
  state = {
    emailList: "",
  }

  onTextAreaInput(value) {
    this.setState({ emailList: value });
  }

  onSubmitReferrals() {
    const { emailList } = this.state;

    if (emailList === "") {
      alert("Isi referral Anda terlebih dahulu");
    } else {
      // fetch API here
    }
  }
  render() {
    return(
      <>
        <Helmet>
          <title>Invite</title>
        </Helmet>
        <div style={{ fontFamily: "Lato" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h1 style={{ fontWeight: 900, fontSize: 32, color: "#333" }} className="mt-5 mb-5">INVITE YOUR FRIEND</h1>
            <textarea style={{ padding: 5 }} onChange={(event) => this.onTextAreaInput(event.target.value)} value={this.state.emailList} rows={4} placeholder="Enter email address (separated by comma)"/>
            <button style={{border: "none", background: "black", color: "white", fontSize: 13, padding: 10 }} onClick={this.onSubmitReferrals}>INVITE</button>
          </div>        
        </div>
      </>
    )
  }
}

export default AccountReferral;